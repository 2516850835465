import styled from "styled-components";

const Wrapper = styled.div`
  background: rgb(255 255 255) !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 20px 20px;
  .ant-menu-sub.ant-menu-inline {
    background: rgb(255 255 255) !important;
  }
  #kt_aside_menu_wrapper {
    padding: 0px 10px !important;
  }
  .ant-menu-inline {
    border-inline-end: none !important;
  }
  .ant-menu-vertical {
    border-inline-end: none !important;
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item-icon {
      vertical-align: -0.325em !important;
    }
    .ant-menu-item {
      padding-inline: calc(50% - 15px) !important;
    }
  }
  .ant-menu-item,
  .ant-menu-item-selected {
    border: 1px solid transparent !important;
    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: #7e8299 !important;
    }
    &:hover {
      box-shadow: 0px 10px 20px 0px transparent;
      background: transparent !important;
      border: 1px dashed rgb(110 175 38) !important;

      svg [fill]:not(.permanent):not(g) {
        transition: fill 0.3s ease;
        fill: rgb(110 175 38) !important;
      }
      .bullet {
        background-color: rgb(110 175 38) !important;
      }
      a {
        color: rgb(110 175 38) !important;
      }
    }
  }
  .ant-menu-item-selected {
    background-color: rgb(110 175 38) !important;
    border: 1px solid transparent !important;
    box-shadow: 0px 10px 20px 0px #6eaf26a6;
    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: #ffffff !important;
    }
    .bullet {
      background-color: #ffffff;
    }
    a {
      color: #fff !important;
    }
  }
  #btnLogout {
    background-color: #fff;
    transition-duration: 200ms !important;
    border: 1px dashed rgb(110 175 38 / 100%);
    transition: 0.6s !important;
    color: rgb(110 175 38 / 100%) !important;
    font-weight: bold;
  }
  #btnLogout: hover {
    color: #FFF !important;
    background-color: rgb(110 175 38 / 100%) !important;
  }
  .ant-menu-submenu {
    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: #7e8299 !important;
    }
    .ant-menu-item {
      padding-left: 38px !important;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: #6eaf26 !important;
      background: #6eaf2624 !important;
    }
    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: #6eaf26 !important;
    }
  }
`;

export default Wrapper;
