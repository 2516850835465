import {
  CheckOutlined,
  CloseOutlined,
  DeleteTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ASINTooltip from "../../../../components/tooltip";
import { DefaultPerPage, timeSince, timeZone } from "../../../../config";
import { Wrapper } from "./style";

import { useSelector } from "react-redux";
import Pagination from "../../../../components/pagination";
import Campaigns from "../../rules copy/lib/Campaigns";
import Add from "./add";

export default function DefaultRules(props) {
  const {
    getDefaultRulesAction,
    DefaultRulesDeleteAction,
    StatusCampaignRulesAction,
    fakeActionDefaultRules,
  } = props;
  const [ruleModal, setRuleModal] = useState(false);
  const [campaignsModal, setCampaignsModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedRow, setSelectedRow] = useState({});

  const [filters, setFilters] = useState({
    rule_name: null,
    status: null,
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const GetCampaignDefaultRuleResponse = useSelector(
    (state) => state.DefaultRules.GetCampaignDefaultRuleResponse || {}
  );
  const DeleteCampaignDefaultRuleResponse = useSelector(
    (state) => state.DefaultRules.DeleteCampaignDefaultRuleResponse || {}
  );
  const StatusDefaultRuleResponse = useSelector(
    (state) => state.DefaultRules.StatusDefaultRuleResponse || {}
  );

  useEffect(() => {
    if (GetCampaignDefaultRuleResponse?.status === true) {
      setList(GetCampaignDefaultRuleResponse.data.records);
      setTotalPage(GetCampaignDefaultRuleResponse.data.pagination.totalCount);
      setLoading(false);
      fakeActionDefaultRules("GetCampaignDefaultRuleResponse");
    } else if (GetCampaignDefaultRuleResponse?.status === false) {
      setLoading(false);
      fakeActionDefaultRules("GetCampaignDefaultRuleResponse");
    }
  }, [GetCampaignDefaultRuleResponse]);

  useEffect(() => {
    if (StatusDefaultRuleResponse?.status === true) {
      message.destroy();
      message.success(StatusDefaultRuleResponse?.message);
      setList((d) => {
        return d?.map((r) => {
          if (r?.id == StatusDefaultRuleResponse?.data?.id) {
            return {
              ...r,
              status: StatusDefaultRuleResponse?.data?.status,
            };
          }
          return { ...r };
        });
      });

      fakeActionDefaultRules("StatusDefaultRuleResponse");
    } else if (StatusDefaultRuleResponse?.status === false) {
      message.destroy();
      message.error(StatusDefaultRuleResponse?.message);
      fakeActionDefaultRules("StatusDefaultRuleResponse");
    }
  }, [StatusDefaultRuleResponse]);

  useEffect(() => {
    if (DeleteCampaignDefaultRuleResponse?.status === true) {
      setLoading(true);
      setPage(1);
      setPageSize(DefaultPerPage);
      getDefaultRulesAction({
        page: 1,
        perPage: DefaultPerPage,
        ...filters,
      });
      message.destroy();
      message.success(
        DeleteCampaignDefaultRuleResponse?.message || "Rule deleted"
      );
      fakeActionDefaultRules("DeleteCampaignDefaultRuleResponse");
    } else if (DeleteCampaignDefaultRuleResponse?.status === false) {
      message.destroy();
      message.warning(
        DeleteCampaignDefaultRuleResponse?.message || "Something Went Wrong."
      );
      fakeActionDefaultRules("DeleteCampaignDefaultRuleResponse");
    }
  }, [DeleteCampaignDefaultRuleResponse]);

  const columns_ = [
    {
      title: "#",
      align: "left",
      fixed: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Rule Name",
      align: "left",
      fixed: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.rule_name || "-"}</ASINTooltip>;
      },
    },

    {
      title: "Rule Details",

      align: "left",
      render: (item) => {
        return (
          <span
            className="Edit-Icon-Antd"
            onClick={() => {
              setModalType("edit");
              setRuleModal(true);
              setSelectedRow(item);
            }}
          >
            <EditTwoTone />
          </span>
        );
      },
    },

    {
      title: "Status",
      align: "center",
      render: (item) => {
        return (
          <span>
            <Tag color={item?.status == 1 ? "green" : "red"} bordered={false}>
              {item?.status == 1 ? "Active" : "In Active"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Created At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },

    {
      title: "Action",
      align: "center",
      width: 180,
      render: (text) => {
        return (
          <div className="d-flex justify-content-around" id="userActionIcons">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(e) => {
                message.destroy();
                message.loading("Loading...", 0);
                StatusCampaignRulesAction(text?.id, { status: e ? 1 : 0 });
              }}
              checked={text?.status == 1}
            />
            <span
              onClick={() => {
                setRuleModal(true);
                setModalType("clone");
                setSelectedRow(text);
              }}
              className="svg-icon svg-icon-primary svg-icon-1 ms-3 cursor-pointer"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                  fill="currentColor"
                />
              </svg>
            </span>

            <Popconfirm
              title="Are you sure to delete this rule?"
              placement="left"
              cancelText="No"
              // getPopupContainer={() =>
              //   document.getElementById("user-table-admin").parentNode
              // }
              okText="Yes"
              onConfirm={() => {
                message.loading("Deleting...", 0);
                DefaultRulesDeleteAction(text?.id);
              }}
            >
              <div className="Delete-Icon-Antd d-flex">
                <Tooltip title="Delete" placement="left">
                  <DeleteTwoTone />
                </Tooltip>
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);
    setPage(e);
    setPageSize(pageSize);
    getDefaultRulesAction({
      page: e,
      perPage: pageSize,
      ...filters,
    });
  };

  const onPerPage = (e) => {
    setLoading(true);
    setPage(1);
    setPageSize(e);
    getDefaultRulesAction({
      page: 1,
      perPage: e,
      ...filters,
    });
  };

  useEffect(() => {
    setLoading(true);
    setPage(1);
    setPageSize(DefaultPerPage);
    getDefaultRulesAction({
      page: 1,
      perPage: DefaultPerPage,
      ...filters,
    });

    return () => {};
  }, []);

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <div>
                <b className="ms-3">Default Rule Group</b>{" "}
              </div>
            </div>
            <div className="card-toolbar">
              <Tooltip title={`Search by Rule Name`}>
                <Input
                  size="large"
                  className="w-200px me-3"
                  value={filters?.rule_name}
                  onPressEnter={(e) => {
                    setLoading(true);
                    setPage(1);
                    setPageSize(DefaultPerPage);
                    getDefaultRulesAction({
                      page: 1,
                      perPage: DefaultPerPage,
                      ...filters,
                    });
                  }}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      rule_name: e.target.value,
                    });
                  }}
                  placeholder="Search..."
                />
              </Tooltip>
              <Select
                placeholder="Select Status"
                size="large"
                className="w-200px me-3"
                allowClear
                onChange={(e) => {
                  setLoading(true);
                  setPage(1);
                  setPageSize(DefaultPerPage);
                  getDefaultRulesAction({
                    page: 1,
                    perPage: DefaultPerPage,
                    ...filters,
                    status: e,
                  });
                  setFilters({ ...filters, status: e });
                }}
                value={filters?.status}
                options={[
                  { label: "Active", value: 2 },
                  { label: "In Active", value: 1 },
                ]}
              />
              <Button
                className="antd-success "
                size="large"
                onClick={() => {
                  setSelectedRow({});
                  setModalType("add");
                  setRuleModal(true);
                }}
              >
                + New Rule
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns_}
              loading={loading}
              dataSource={list}
              rowKey="id"
              pagination={false}
              // pagination={{
              //   showTotal: (total, range) =>
              //     `${range[0]}-${range[1]} of ${total} items`,
              // }}
              scroll={{ x: "max-content" }}
            />
            <Pagination
              loading={loading || list?.length === 0}
              pageSize={pageSize}
              page={page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            />
          </div>
        </div>
      </div>
      {ruleModal && (
        <Add
          show={ruleModal}
          dataValues={selectedRow}
          getList={() => {
            setLoading(true);
            setPage(1);
            setPageSize(DefaultPerPage);
            getDefaultRulesAction({
              page: 1,
              perPage: DefaultPerPage,
              ...filters,
            });
          }}
          GroupData={[]}
          modalType={modalType}
          marketplaceSelected={""}
          type={Object.keys(selectedRow)?.length === 0}
          close={() => {
            setModalType("add");
            setSelectedRow({});
            setRuleModal(false);
          }}
          {...props}
        />
      )}
      <Campaigns
        show={campaignsModal}
        close={() => setCampaignsModal(false)}
        data={selectedRow}
        CompaignRulesGetCompaingsAction={() => {}}
      />
    </Wrapper>
  );
}
