import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Wrapper from "../../style";
import { adminMenus, testArr } from "../../../../../config";

const mockSignUpResponse = {
  status: true,
  data: {
    auth_token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6ImZoXldpcCFhRzZETHNtaFMifQ.eyJpc3MiOiJ0cmFrdG9yIiwiYXVkIjoidHJha3RvciIsImp0aSI6ImZoXldpcCFhRzZETHNtaFMiLCJpYXQiOjE3MTg3MDI3NzQsImV4cCI6MTcxODc4OTE3NCwidWlkIjozMn0.4_y5g63OdB0kVvE1KWMxbr_MedndQuV5HLhZfQIAdkc",
    user_data: {
      id: 32,
      rds_credential_id: 1,
      server_credential_id: 1,
      parent: null,
      name: "signup test 2",
      seller_name: "signup test 2",
      user_type: 1,
      email: "signup2@traktor.com",
      photo: null,
      db_name: null,
      contact_no: null,
      address: null,
      company_registration_number: null,
      company_name: null,
      vat: null,
      status: null,
      pending_reports: null,
      avg_order_per_day: null,
      avg_sku: null,
      total_sales: null,
      total_spend: null,
      is_big_data: null,
      user_status: null,
      amazon_advertising_client_id: null,
      amazon_advertising_client_secret: null,
      amazon_advertising_return_url: null,
      amazon_advertising_response: null,
      amazon_advertising_access_token: null,
      amazon_advertising_refresh_token: null,
      amazon_advertising_access_token_expiry: null,
      amazon_advertising_token_type: null,
      created_at: 1718702769,
      updated_at: 1718702769,
      deleted_at: null,
      deleted_by: null,
    },
    // Add other data properties as needed
  },
  message: "Mocked sign up successful message", // Change message for testing different scenarios
};

const SignUp = ({
  form,
  signUpAction,
  fakeActionAuth,
  loading,
  setViewStatus,
  setLoading,
  viewStatus,
}) => {
  const focusFirst = useRef();
  // const [SignUpResponse, setSignUpResponse] = useState(null);
  const history = useHistory();

  const SignUpResponse = useSelector(
    (state) => state.Auth.SignUpResponse || {}
  );

  useEffect(() => {
    if (SignUpResponse?.status === true) {
      setLoading(false);
      setViewStatus("/login");
      message.destroy();
      message.success(SignUpResponse?.message);
      fakeActionAuth("SignUpResponse");
    } else if (SignUpResponse?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(SignUpResponse?.message || "Something Went Wrong");
      fakeActionAuth("SignUpResponse");
    }
  }, [SignUpResponse]);

  const isLogin = (value) => {
    console.log(value, "value");
    localStorage.clear();
    signUpAction(value);
    setLoading(true);
    // setTimeout(() => {
    //   setSignUpResponse(mockSignUpResponse);
    // }, 5000);
  };

  return (
    <Wrapper
      id="login_"
      className="d-flex flex-column flex-lg-row flex-column-fluid"
    >
      <div className="d-flex flex-lg-row-fluid d-none d-md-flex">
        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
          <img
            className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
            src="/assets/logo-main.png"
            alt
          />
          <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">
            Fast, Efficient and Productive
          </h1>
        </div>
      </div>
      <div className="d-flex flex-column-fluid justify-content-center justify-content-lg-end px-15 py-8">
        <div
          className="authFadeInBottom bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10"
          style={{ zoom: "85%", height: "fit-content", margin: "auto" }}
        >
          <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
            <div className="d-flex flex-center flex-column-fluid pb-10 pb-lg-15">
              <form className="form w-100">
                <div className="text-center mb-10">
                  <img className="h-55px shimmer" src="/assets/logo.png" alt />
                </div>
                <div className="text-center mb-10">
                  <h1 className="text-dark fw-bolder mb-3">
                    Sign Up To Traktor
                  </h1>
                  <div className="text-gray-500 fw-semibold fs-6">
                    Your Social Campaigns
                  </div>
                </div>
                <Form
                  form={form}
                  name="login"
                  scrollToFirstError
                  layout="vertical"
                >
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Name",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      size="large"
                      autoFocus
                      ref={focusFirst}
                      placeholder="Name"
                      id="name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      size="large"
                      autoFocus
                      ref={focusFirst}
                      placeholder="E-mail"
                      id="username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      id="password"
                      size="large"
                      placeholder="Password"
                    />
                  </Form.Item>
                </Form>
                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5">
                  <div />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewStatus("forgot-password")}
                    className="link-primary"
                  >
                    Forget Password ?
                  </span>
                </div>
                <div className="d-grid mb-10">
                  <Button
                    loading={loading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          isLogin(values);
                        })
                        .catch((info) => {});
                    }}
                    size="large"
                    className="antd-success"
                  >
                    Sign Up
                  </Button>
                </div>
                <div className="text-gray-500 text-center fw-semibold fs-6">
                  Already a Member?
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewStatus("login")}
                    className="link-primary"
                  >
                    Sign In
                  </span>
                </div>
              </form>
            </div>
            <div style={{ alignSelf: "center" }} className="d-flex flex-stack">
              <div className="d-flex fw-semibold text-primary fs-base gap-5">
                <Link>Terms</Link>
                <Link>Plans</Link>
                <Link>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SignUp;
