/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
import { Input, Select, Tabs, Tag, message } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../components/loading";
import Pagination from "../../../../components/pagination";
import ASINTable from "../../../../components/table";
import ASINTooltip from "../../../../components/tooltip";
import { DefaultPerPage, timeSince, timeZone } from "../../../../config";
import { userCentralLogEventStatus } from "../../../../config/static-select-options";
import Wrapper from "./style";

export default function (props) {
  const {
    GetSystemEventProcessAction,
    GetMarketplacesAction,
    EventNameListAction,
    fakeActionAppLog,
  } = props;

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [data, setData] = useState([]);

  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceList, setMarketplaceList] = useState([]);

  const [tab, setTab] = useState("1");

  const [eventName, setEventName] = useState([]);
  const [eventNameLoading, setEventNameLoading] = useState(true);

  const [filter, setFilter] = useState({
    event_name: "",
    event_status: "",
    marketplace_id: "",
    event_type: "Campaign Rule",
    rule_name: "",
  });

  const GetSystemEventProcessResponse = useSelector(
    (state) => state.AppLogs.GetSystemEventProcessResponse || {}
  );
  const GetMarketplaceListResponse = useSelector(
    (state) => state.AppLogs.GetMarketplaceListResponse || {}
  );

  const EventNameListResponse = useSelector(
    (state) => state.AppLogs.EventNameListResponse || {}
  );

  useEffect(() => {
    setLoading(true);
    GetSystemEventProcessAction(filter);
    setMarketplaceLoading(true);
    GetMarketplacesAction();
    setEventNameLoading(true);
    EventNameListAction();

    return () => {};
  }, []);

  useEffect(() => {
    if (EventNameListResponse?.status === true) {
      setEventName(
        Object.entries(EventNameListResponse?.data)?.map((d) => {
          return {
            label: d?.[1],
            value: d?.[0],
          };
        })
      );
      setEventNameLoading(false);
      fakeActionAppLog("EventNameListResponse");
    } else if (EventNameListResponse?.status === false) {
      setEventName([]);
      setEventNameLoading(false);
      fakeActionAppLog("EventNameListResponse");
    }
  }, [EventNameListResponse]);

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      setMarketplaceList(
        GetMarketplaceListResponse.data?.map((d) => {
          return { value: d.marketplace_id, label: d.marketplace, ...d };
        })
      );
      setMarketplaceLoading(false);
      fakeActionAppLog("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      message.warning(GetMarketplaceListResponse?.message);
      setMarketplaceLoading(false);
      fakeActionAppLog("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  useEffect(() => {
    if (GetSystemEventProcessResponse?.status === true) {
      setLoading(false);
      setData(GetSystemEventProcessResponse.data.records);
      setTotalPage(GetSystemEventProcessResponse.data.pagination.totalCount);
      fakeActionAppLog("GetSystemEventProcessResponse");
    } else if (GetSystemEventProcessResponse?.status === false) {
      setLoading(false);
      message.warning(GetSystemEventProcessResponse?.message);
      fakeActionAppLog("GetSystemEventProcessResponse");
    }
  }, [GetSystemEventProcessResponse]);

  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    GetSystemEventProcessAction({
      page: e,
      perPage: pageSize,
      ...filter,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetSystemEventProcessAction({
      page: 1,
      perPage: e,
      ...filter,
    });
  };

  const shouldShowRuleNameColumn = data.some(
    (item) => item?.event_type === "Campaign Rule"
  );

  const columns = [
    {
      title: "#",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log created",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.created_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.created_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Date",
      render: (item) => {
        if (
          item?.event_type === "SP API Report" ||
          item?.event_type === "Financial API"
        ) {
          return (
            <>
              <div
                className="d-grid"
                style={{
                  justifyItems: "center",
                  justifyContent: "start",
                  height: "55px",
                }}
              >
                <span>
                  {moment(
                    new Date(
                      JSON.parse(item?.event_details)?.[
                        item?.event_type === "Financial API"
                          ? "start_date"
                          : "data_start_time"
                      ]
                    )
                  ).format("MM/DD/YYYY hh:mm A")}
                </span>
                to
                <br />
                <span>
                  {moment(
                    new Date(
                      JSON.parse(item?.event_details)?.[
                        item?.event_type === "Financial API"
                          ? "end_date"
                          : "data_end_time"
                      ]
                    )
                  ).format("MM/DD/YYYY hh:mm A")}
                </span>
                <br />
              </div>
            </>
          );
        } else if (
          item?.event_type === "SKU Wise" ||
          item?.event_type === "Brand Wise"
        ) {
          return (
            <>
              <div
                className="d-grid"
                style={{
                  justifyItems: "center",
                  justifyContent: "start",
                  height: "55px",
                }}
              >
                <span>
                  {moment(
                    new Date(JSON.parse(item?.event_details)?.["startDate"])
                  ).format("MM/DD/YYYY hh:mm A")}
                </span>
                to
                <br />
                <span>
                  {moment(
                    new Date(JSON.parse(item?.event_details)?.["endDate"])
                  ).format("MM/DD/YYYY hh:mm A")}
                </span>
                <br />
              </div>
            </>
          );
        } else {
          return item?.event_date;
        }
      },
    },
    shouldShowRuleNameColumn
      ? {
          title: "Rule Name",
          ellipsis: false,
          render: (d) => {
            // Only render Rule Name if event_type is "Campaign Rule"
            if (d?.event_type === "Campaign Rule") {
              const rule_name_ = JSON.parse(d?.event_details || {})?.rule_name;

              return (
                <b>
                  <ASINTooltip rule row={1}>
                    <div className="d-grid">{rule_name_}</div>
                  </ASINTooltip>
                </b>
              );
            } else {
              return null; // Return null if event_type is not "Campaign Rule"
            }
          },
        }
      : null,
    {
      title: "Event Name",
      ellipsis: false,
      render: (d) => {
        const rule_name_ = JSON.parse(d?.event_details || {})?.rule_name;

        console.log(d, d?.event_type, "detail");
        return (
          <>
            {
              <b>
                <ASINTooltip rule row={1}>
                  <div className="d-grid">
                    <div>
                      {d?.event_name?.split("_").join(" ").toLowerCase() ||
                        "N/A"}
                      <span style={{ fontWeight: 500 }}>
                        {d?.event_type === "Campaign Rule" && rule_name_
                          ? `(${rule_name_ || "-"})`
                          : ""}
                      </span>
                      <span style={{ fontWeight: 500 }}>
                        {d?.event_type === "Advertising Report"
                          ? `(${
                              JSON.parse(d?.event_details || {})?.record_type
                            })`
                          : ""}
                      </span>
                    </div>
                    <div>{JSON.parse(d?.event_details || {})?.tactic_name}</div>
                  </div>
                </ASINTooltip>
              </b>
            }
          </>
        );
      },
    },
    {
      title: "Event Status",

      render: (item) => {
        return (
          <Tag
            color={
              item.event_status === "PENDING"
                ? "warning"
                : item.event_status === "IN_PROGRESS"
                ? "processing"
                : item.event_status === "DONE"
                ? "success"
                : item.event_status === "FAILED"
                ? "error"
                : item.event_status === "FATAL"
                ? "error"
                : item.event_status === "CANCELLED"
                ? "error"
                : "error"
            }
          >
            {item.event_status || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Priority",

      render: (item) => {
        return <b>{item?.priority || "N/A"}</b>;
      },
    },
    {
      title: "Marketplace",

      render: (item) => {
        return <b>{item?.marketplace || "N/A"}</b>;
      },
    },
    {
      title: "Updated at",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .tz(timeZone)
                .format("MM/DD/YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.updated_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Report Requested",
      render: (item) => {
        return (
          <Tag color={item.status === 0 ? "processing" : "success"}>
            {item.status === 0 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },
    {
      title: "Reason",
      render: (item) => {
        return (
          <ASINTooltip rule row={2}>
            {item?.note || "-"}
          </ASINTooltip>
        );
      },
    },
  ];

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <h2 className="mb-6 smallScreenPageHeader">Central Log System</h2>
        <Tabs
          activeKey={tab}
          onChange={(e) => {
            const obj = {
              ...filter,
              event_type: e == 1 ? "Campaign Rule" : "Advertising Report",
            };
            setFilter(obj);
            setTab(e);
            setLoading(true);
            GetSystemEventProcessAction(obj);
          }}
          type="card"
          items={["Rules", "Reports"].map((_, i) => {
            const id = String(i + 1);
            return {
              label: _,
              key: id,
              children: "",
            };
          })}
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header border-bottom border-bottom-dashed">
                <div className="d-flex flex-stack flex-wrap gap-4 py-4 central-log-user-filter">
                  {tab == 2 && (
                    <>
                      <div className="position-relative mr-4">
                        <Select
                          allowClear
                          loading={marketplaceLoading}
                          options={marketplaceList}
                          placeholder="Select Marketplace"
                          size="large"
                          className="w-200px"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={filter?.marketplace_id || null}
                          onChange={(e) => {
                            const obj = {
                              ...filter,
                              marketplace_id: e,
                            };
                            setLoading(true);
                            setData([]);
                            GetSystemEventProcessAction({
                              ...obj,
                              page: page,
                              perPage: pageSize,
                            });
                            setFilter(obj);
                          }}
                        />
                      </div>
                      <div className="position-relative mr-4">
                        <Select
                          className="w-200px"
                          placeholder="Select Event Name"
                          allowClear
                          size="large"
                          loading={eventNameLoading}
                          options={eventName}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={filter?.event_name || null}
                          onChange={(e) => {
                            const obj = {
                              ...filter,
                              event_name: e,
                            };
                            setLoading(true);
                            setData([]);
                            GetSystemEventProcessAction({
                              ...obj,
                              page: page,
                              perPage: pageSize,
                            });
                            setFilter(obj);
                          }}
                        />
                      </div>
                      <div className="position-relative mr-4">
                        <Select
                          className="w-200px"
                          placeholder="Select Event Status"
                          allowClear
                          size="large"
                          options={userCentralLogEventStatus}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={filter?.event_status || null}
                          onChange={(e) => {
                            const obj = {
                              ...filter,
                              event_status: e,
                            };
                            setLoading(true);
                            setData([]);
                            GetSystemEventProcessAction({
                              ...obj,
                              page: page,
                              perPage: pageSize,
                            });
                            setFilter(obj);
                          }}
                        />
                      </div>
                    </>
                  )}
                  {tab == 1 && (
                    <div className="position-relative mr-4">
                      <Input
                        className="w-200px"
                        placeholder="Enter Rule Name"
                        size="large"
                        onPressEnter={() => {
                          setLoading(true);
                          GetSystemEventProcessAction(filter);
                        }}
                        value={filter?.rule_name || null}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            rule_name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="card-body py-4">
                <div className="table-responsive">
                  {loading ? (
                    <Loading />
                  ) : (
                    <ASINTable
                      columns={columns}
                      dataSource={data}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  )}
                </div>
                <Pagination
                  loading={loading || data?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
