import axiosCall from "../../../configurations/network-services/axiosCall";

export const AdvertisingSBCampaignsAction = (data) => {
  const path = `advertising/sb/campaigns?page=${data?.page || 1}&per-page=${data?.perPage || 10}`;
  const responseType = "ADVERTISING_S_B_CAMPAIGNS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAdvertisingSB = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ADVERTISING_SB", state: data });
};
