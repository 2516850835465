import styled from "styled-components";

const Wrapper = styled.div`
  .campaignsView {
    display: flex;
    align-items: center;
    .viewMore {
      span {
        color: grey;
        font-size: 20px;
        margin-left: 20px;
        &:hover {
          color: #444444;
        }
      }
    }
  }
`;
export const ModalWrapper = styled.div`
  .ant-collapse-header-text {
    font-weight: 700;
    letter-spacing: 1px;
  }
  .ant-input-number-group-addon {
    background: transparent !important;
    border: transparent !important;
  }
  small {
    font-size: 0.875em;
  }
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 20px;
  .ant-checkbox + span {
    padding-inline-start: 5px !important;
    padding-inline-end: 0px !important;
  }
`;
export default Wrapper;
