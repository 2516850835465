import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Wrapper from "../../style";

const mockResetPasswordResponse = {
  status: true,
  message: "Mocked Reset Password successful message", // Change message for testing different scenarios
};

const ForgotPassword = ({
  form,
  fakeActionAuth,
  forgotPasswordAction,
  resetPasswordAction,
  loading,
  setViewStatus,
  setLoading,
  viewStatus,
}) => {
  const focusFirst = useRef();
  const [token, setToken] = useState(null);
  const [ResetPasswordResponse, setResetPasswordResponse] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const ForgotPasswordResponse = useSelector(
    (state) => state.Auth.ForgotPasswordResponse || {}
  );

  // const ResetPasswordResponse = useSelector(
  //   (state) => state.Auth.ResetPasswordResponse || {}
  // );

  useEffect(() => {
    if (ForgotPasswordResponse?.status === true) {
      setLoading(false);
      message.destroy();
      message.success(ForgotPasswordResponse?.message);
      fakeActionAuth("ForgotPasswordResponse");
    } else if (ForgotPasswordResponse?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(
        ForgotPasswordResponse?.message || "Something Went Wrong"
      );
      fakeActionAuth("ForgotPasswordResponse");
    }
  }, [ForgotPasswordResponse]);

  useEffect(() => {
    if (ResetPasswordResponse?.status === true) {
      message.destroy();
      setLoading(false);
      history.replace("/login");
      message.success(ResetPasswordResponse?.message);
      fakeActionAuth("ResetPasswordResponse");
    } else if (ResetPasswordResponse?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(ResetPasswordResponse?.message || "Something Went Wrong");
      fakeActionAuth("ResetPasswordResponse");
    }
  }, [ResetPasswordResponse]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get("token");
    console.log("tokenFromUrl", tokenFromUrl);
    if (tokenFromUrl) {
      // Set email field value to token and set token state
      form.setFieldValue("email", tokenFromUrl);
      setToken(tokenFromUrl);

      // // Remove token parameter from URL and replace the current URL
      searchParams.delete("token");
      history.replace({
        search: searchParams.toString(),
      });
    }
  }, [location]);

  const onFinish = (value) => {
    setLoading(true);
    const { email, newPassword, password } = value;

    localStorage.clear();
    if (token) {
      const requestBody = {
        email,
        password,
      };
      console.log("Raw Request:", requestBody); // Log the raw request
      console.log("token");
      setLoading(true);
      message.destroy();
      message.loading("Loading...");
      setTimeout(() => {
        setResetPasswordResponse(mockResetPasswordResponse);
      }, 5000);
      // resetPasswordAction(value);
    } else {
      console.log("Without token");
      const requestBody = {
        email,
      };
      console.log("Raw Request:", requestBody); // Log the raw request
      setLoading(true);
      message.destroy();
      message.loading("Loading...");
      // forgotPasswordAction(value);
    }
  };

  return (
    <Wrapper
      id="login_"
      className="d-flex flex-column flex-lg-row flex-column-fluid"
    >
      <div className="d-flex flex-lg-row-fluid d-none d-md-flex">
        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
          <img
            className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
            src="/assets/logo-main.png"
            alt
          />
          <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">
            Fast, Efficient and Productive
          </h1>
        </div>
      </div>
      <div className="d-flex flex-column-fluid justify-content-center justify-content-lg-end px-15 py-8">
        <div
          className="authFadeInBottom bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10"
          style={{ zoom: "85%", height: "fit-content", margin: "auto" }}
        >
          <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
            <div className="d-flex flex-center flex-column-fluid pb-10 pb-lg-15">
              <form className="form w-100">
                <div className="text-center mb-10">
                  <img className="h-55px shimmer" src="/assets/logo.png" alt />
                </div>
                <div className="text-center mb-10">
                  <h1 className="text-dark fw-bolder mb-3">
                    Forgot Your Password?
                  </h1>
                  <div className="text-gray-500 fw-semibold fs-6">
                    Reset It Below and Get Back to Your Campaigns!
                  </div>
                </div>
                <Form
                  form={form}
                  name="login"
                  scrollToFirstError
                  layout="vertical"
                >
                  <Form.Item
                    name="email"
                    label="E-mail"
                    style={{ display: !token ? "block" : "none" }}
                    rules={
                      !token
                        ? [
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]
                        : [
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]
                    }
                    hasFeedback
                  >
                    <Input
                      size="large"
                      autoFocus
                      ref={focusFirst}
                      placeholder="E-mail"
                      id="username"
                    />
                  </Form.Item>

                  {token && (
                    <>
                      <Form.Item
                        name="newPassword"
                        label="New Password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your new password!",
                          },
                          {
                            min: 6,
                            message:
                              "Password must be at least 6 characters long!",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          size="large"
                          placeholder="New Password"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Confirm New Password"
                        dependencies={["newPassword"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your new password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("newPassword") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two passwords do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Confirm New Password"
                        />
                      </Form.Item>
                    </>
                  )}
                </Form>
                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5">
                  <div />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewStatus("forgot-password")}
                    className="link-primary"
                  >
                    {!viewStatus === "forgot-password" && "Forget Password ?"}
                  </span>
                </div>
                <div className="d-grid mb-10">
                  <Button
                    loading={loading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          onFinish(values);
                        })
                        .catch((info) => {});
                    }}
                    size="large"
                    className="antd-success"
                  >
                    Send
                  </Button>
                </div>
                <div className="text-gray-500 text-center fw-semibold fs-6">
                  Already a Member?
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewStatus("login")}
                    className="link-primary"
                  >
                    Sign In
                  </span>
                </div>
              </form>
            </div>
            <div style={{ alignSelf: "center" }} className="d-flex flex-stack">
              <div className="d-flex fw-semibold text-primary fs-base gap-5">
                <Link>Terms</Link>
                <Link>Plans</Link>
                <Link>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ForgotPassword;
