import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import "./style.css";

const ASINTooltip = (props) => {
  const {
    loading,
    rule,
    title,
    placement,
    children,
    row = 1,
    view = true,
    ...rest
  } = props;
  const [visible, setVisible] = useState(false);
  let container = useRef();
  const handleVisibleChange = (_visible) => {
    if (view === false) {
      return;
    }
    if (rule === undefined || rule === null) {
      return setVisible(_visible);
    }
    if (
      row &&
      container.current &&
      container.current.clientHeight < container.current.scrollHeight
    ) {
      return setVisible(_visible);
    }
    if (
      container.current &&
      container.current.clientWidth < container.current.scrollWidth
    ) {
      return setVisible(_visible);
    }
  };
  useEffect(() => {
    if (loading === false) {
      setVisible(false);
    }
  }, [loading]);

  const verticalStyle = {
    WebkitLineClamp: row,
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  };

  return (
    <Tooltip
      mouseLeaveDelay={0}
      mouseEnterDelay={0.15}
      destroyTooltipOnHide
      placement={placement || "bottom"}
      open={visible}
      title={title || children}
      arrow
      onOpenChange={handleVisibleChange}
    >
      <div
        ref={(node) => (container.current = node)}
        data-test={`_${title}`}
        style={row ? { ...verticalStyle } : {}}
        className={` ${row ? "line-clamp" : "tooltipBox"}`}
        {...rest}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default ASINTooltip;
