import React, { useEffect, useState } from "react";
import { Modal, Table, message } from "antd";
import { useSelector } from "react-redux";
const marketplaceObj = {
  A2Q3Y263D00KWC: "Amazon.com.br",
  A2EUQ1WTGCTBG2: "Amazon.ca",
  A1AM78C64UM0Y8: "Amazon.com.mx",
  ATVPDKIKX0DER: "Amazon.com",
  A2VIGQ35RCS4UG: "Amazon.ae",
  A1PA6795UKMFR9: "Amazon.de",
  A1RKKUPIHCS9HS: "Amazon.es",
  A13V1IB3VIYZZH: "Amazon.fr",
  A1F83G8C2ARO7P: "Amazon.uk",
  A21TJRUUN4KGV: "Amazon.in",
  APJ6JRA9NG5V4: "Amazon.it",
  A1805IZSGTT6HS: "Amazon.nl",
  A17E79C6D8DWNP: "Amazon.sa",
  A33AVAJ2PDY3EV: "Amazon.tr",
  A19VAU5U5O7RUS: "Amazon.sg",
  A1VC38T7YXB528: "Amazon.jp",
  AAHKV2X7AFYLW: "Amazon.cn",
};
const numberWithCommas = (x) => {
  if (x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  }
  return x;
};
const columns = [
  {
    title: "Ad Profile Id",
    dataIndex: "profileId",
    render: (e) => {
      return <span>{e}</span>;
    },
  },
  {
    title: "Country Code",
    dataIndex: "countryCode",
  },
  {
    title: "Seller Id",
    render: (text) => {
      return <span>{text?.accountInfo?.sellerStringId || "-"}</span>;
    },
  },
  {
    title: "Daily Budget",
    render: (text) => {
      return <span>{numberWithCommas(text?.dailyBudget || 0)}</span>;
    },
  },
  {
    title: "Time Zone",
    dataIndex: "timezone",
  },
  {
    title: "Currency Code",
    dataIndex: "currencyCode",
  },
  {
    title: "Marketplace",
    render: (text) => {
      return (
        <span>
          {marketplaceObj[text?.accountInfo?.marketplaceStringId] || "-"}
        </span>
      );
    },
  },

  {
    title: "Marketplace Id",
    render: (text) => {
      return <span>{text?.accountInfo?.marketplaceStringId || "-"}</span>;
    },
  },
];

export default function (props) {
  const {
    show,
    onHide,
    data,
    UserCredentialSaveAdProfileAction,
    fakeActionMarketplaceCredentials,
    GetMarketplacesCredentialsAction,
    getList,
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const GetMarketplaceCredentialsResponse = useSelector(
    (state) =>
      state.MarketplaceCredentials?.GetMarketplaceCredentialsResponse || {}
  );
  const UserCredentialSaveAdProfileResponse = useSelector(
    (state) =>
      state.MarketplaceCredentials?.UserCredentialSaveAdProfileResponse || {}
  );

  useEffect(() => {
    if (GetMarketplaceCredentialsResponse?.status === true) {
      const selectedRowKeys_ = [];
      GetMarketplaceCredentialsResponse?.data?.forEach((e) => {
        const index = data.findIndex((d) => d.profileId === e.profile_id);
        if (index !== -1) {
          selectedRowKeys_.push(data[index]);
        }
      });
      setSelectedRowKeys(selectedRowKeys_);
      setLoading(false);
      fakeActionMarketplaceCredentials("GetMarketplaceCredentialsResponse");
    } else if (GetMarketplaceCredentialsResponse?.status === false) {
      setLoading(false);
      fakeActionMarketplaceCredentials("GetMarketplaceCredentialsResponse");
    }
  }, [GetMarketplaceCredentialsResponse]);

  useEffect(() => {
    setLoading(true);
    GetMarketplacesCredentialsAction();
    return () => {};
  }, []);

  useEffect(() => {
    if (UserCredentialSaveAdProfileResponse?.status === true) {
      getList();
      message.destroy();
      message.success(UserCredentialSaveAdProfileResponse?.message);
      onHide();
      fakeActionMarketplaceCredentials("UserCredentialSaveAdProfileResponse");
    } else if (UserCredentialSaveAdProfileResponse?.status === false) {
      message.destroy();
      message.error(UserCredentialSaveAdProfileResponse?.message);
      fakeActionMarketplaceCredentials("UserCredentialSaveAdProfileResponse");
    }
  }, [UserCredentialSaveAdProfileResponse]);

  const onSelectChange = (_, newSelectedRow) => {
    setSelectedRowKeys(newSelectedRow);
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys?.map((d) => d.key),
    onChange: onSelectChange,
  };
  return (
    <Modal
      open={show}
      onCancel={onHide}
      title="Advertising Profile Data"
      width={800}
      style={{ zIndex: 9999 }}
      footer={[
        <button
          disabled={selectedRowKeys?.length === 0}
          onClick={() => {
            if (selectedRowKeys?.length !== 0) {
              message.destroy();
              message.loading("Loading...", 0);
              UserCredentialSaveAdProfileAction({
                ad_profile_ids: selectedRowKeys?.map((d) => d?.profileId),
              });
            }
          }}
          type="button"
          className="btn btn-primary px-7"
        >
          Save
        </button>,
      ]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
}
