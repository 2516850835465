import {
  Button,
  Checkbox,
  Collapse,
  Input,
  Modal,
  Radio,
  Select,
  Tag,
  TimePicker,
  message,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../../components/icons";
import { ModalWrapper } from "../../rules copy/style";
import {
  OperatorList,
  RuleFelids,
  weekdays,
} from "../../../../config/static-select-options";
// import { ModalWrapper } from "../style";

const { Panel } = Collapse;

const format = "hh:mm A";
const NumberWithDecimal = (value) => {
  return value;
};

export default function (props) {
  const {
    show,
    close,
    type,
    dataValues,
    modalType,
    AddCampaignRulesDefaultAction,
    EditCampaignRulesDefaultAction,
    fakeActionDefaultRules,
    getList,
    marketplaceSelected,
    GroupData,
  } = props;

  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(
    Object.keys(dataValues)?.length === 0 && modalType === "add"
      ? {
          rule_name: "",

          criteria: [
            {
              field_name: "budget",
              operator: "<",
              value: "",
            },
          ],
          lookback_window: "today",
          daily_budget_type: "increase",
          daily_budget: "",
          frequency: 5,
        }
      : {
          ...dataValues,
          rule_name:
            dataValues?.rule_name + (modalType === "clone" ? "_clone" : ""),
          criteria:
            typeof dataValues?.criteria === "string" && dataValues?.criteria
              ? JSON.parse(dataValues?.criteria || [])
              : [
                  {
                    field_name: "budget",
                    operator: "<",
                    value: "",
                  },
                  {
                    field_name: "budget",
                    operator: "<",
                    value: "",
                  },
                ],
        }
  );
  const AddCampaignRulesDefaultResponse = useSelector(
    (state) => state.DefaultRules.AddCampaignRulesDefaultResponse || {}
  );
  const EditCampaignRulesDefaultResponse = useSelector(
    (state) => state.DefaultRules.EditCampaignRulesDefaultResponse || {}
  );

  useEffect(() => {
    if (EditCampaignRulesDefaultResponse?.status === true) {
      setSubmit(false);
      getList();
      setLoading(false);
      message.destroy();
      message.success(
        EditCampaignRulesDefaultResponse?.message || "Rule Updated"
      );
      close();
      fakeActionDefaultRules("EditCampaignRulesDefaultResponse");
    } else if (EditCampaignRulesDefaultResponse?.status === false) {
      setSubmit(false);
      setLoading(false);
      message.destroy();
      message.warning(
        EditCampaignRulesDefaultResponse?.message || "Something Went Wrong."
      );
      fakeActionDefaultRules("EditCampaignRulesDefaultResponse");
    }
  }, [EditCampaignRulesDefaultResponse]);
  useEffect(() => {
    if (AddCampaignRulesDefaultResponse?.status === true) {
      setSubmit(false);
      getList();
      setLoading(false);
      message.destroy();
      message.success(
        AddCampaignRulesDefaultResponse?.message || "Rule Created"
      );
      close();
      fakeActionDefaultRules("AddCampaignRulesDefaultResponse");
    } else if (AddCampaignRulesDefaultResponse?.status === false) {
      setSubmit(false);
      setLoading(false);
      message.destroy();
      message.warning(
        AddCampaignRulesDefaultResponse?.message || "Something Went Wrong."
      );
      fakeActionDefaultRules("AddCampaignRulesDefaultResponse");
    }
  }, [AddCampaignRulesDefaultResponse]);

  const onChangeValues = (e, i) => {
    setData({
      ...data,
      criteria: data.criteria?.map((t, k) => {
        if (k === i) {
          return {
            ...t,
            value: e,
          };
        }
        return { ...t };
      }),
    });
  };

  const isEmpty = (value) => {
    if (typeof value === "object" && value !== null) {
      for (const v of Object.values(value)) {
        if (isEmpty(v)) return true;
      }
    }

    return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  };
  return (
    <Modal
      title={
        <div className="d-flex align-items-center">
          {modalType === "clone"
            ? "Clone Rule"
            : modalType === "add"
            ? "Add Rule"
            : `Edit Rule `}
          {modalType !== "clone" && modalType !== "add" && (
            <Tag color="green" bordered={false}>
              {dataValues?.rule_name}
            </Tag>
          )}
        </div>
      }
      open={show}
      width={900}
      centered
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          Close
        </Button>,
        <Button
          className="antd-success"
          key="back"
          loading={loading}
          onClick={() => {
            const obj_ = {
              ...data,
            };
            setSubmit(true);

            if (modalType !== "edit") {
              delete obj_?.marketplace_id;
              delete obj_?.asin_list;

              delete obj_?.profile_id;
              delete obj_?.groups_id;
              if (!isEmpty(obj_)) {
                message.destroy();
                message.loading("Loading...", 0);
                setLoading(true);
                AddCampaignRulesDefaultAction({
                  ...obj_,
                  profile_id: marketplaceSelected,
                });
              }
            } else {
              delete obj_?.campaigns;
              delete obj_?.asin_list;
              delete obj_?.marketplace_id;
              delete obj_?.profile_id;
              delete obj_?.groups_id;
              if (!isEmpty(obj_)) {
                message.destroy();
                message.loading("Loading...", 0);
                setLoading(true);
                EditCampaignRulesDefaultAction(dataValues?.id, {
                  ...obj_,
                  profile_id: marketplaceSelected,
                });
              }
            }
          }}
        >
          Save
        </Button>,
      ]}
    >
      <ModalWrapper id="add_rules">
        <div className="row mb-4">
          <div className="col-12">
            <div className="position-relative mr-4">
              <label className="fw-bolder mb-1">Rule Name</label>
              <Input
                placeholder="Enter Rule Name"
                size="large"
                value={data?.rule_name}
                onChange={(e) => {
                  setData({
                    ...data,
                    rule_name: e.target.value,
                  });
                }}
              />
              {submit && !data?.rule_name && (
                <span style={{ color: "red" }}>Rule name is required</span>
              )}
            </div>
          </div>
        </div>
        <Collapse
          bordered={false}
          defaultActiveKey="1"
          expandIcon={({ isActive }) => (
            <Icons
              type="Collapse_Arrow"
              style={{
                transform: `rotate(${isActive ? "90" : "0"}deg)`,
                transition: "0.6s",
              }}
            />
          )}
          style={{ background: "#FFF" }}
        >
          <Panel header="Criteria" key="1" style={{ borderBottom: "none" }}>
            <div className="ps-10">
              {data?.criteria?.map((d, i) => (
                <div className="row g-9 mb-5 align-items-center" key={i}>
                  <div className="col-md-1 ">
                    <div className="d-grid">
                      <label className=" fs-5 fw-bold mb-0">If</label>
                      <span style={{ opacity: 0 }}>-</span>
                    </div>
                  </div>
                  <div className="col-md-3 ">
                    <>
                      <Select
                        placeholder="Field Name"
                        style={{ width: "100%" }}
                        size="large"
                        options={RuleFelids}
                        value={d?.field_name}
                        onChange={(e) => {
                          setData({
                            ...data,
                            criteria: data.criteria?.map((t, k) => {
                              if (k === i) {
                                return {
                                  ...t,
                                  field_name: e,
                                  operator:
                                    e === "hour_of_the_day" || e === "weekday"
                                      ? "is"
                                      : e === "status"
                                      ? "is"
                                      : e === "campaign_status"
                                      ? "is"
                                      : null,
                                  value:
                                    e === "hour_of_the_day"
                                      ? {
                                          start_time: null,
                                          end_time: null,
                                        }
                                      : e === "status"
                                      ? "active"
                                      : e === "campaign_status"
                                      ? "out_of_budget"
                                      : null,
                                };
                              }
                              return { ...t };
                            }),
                          });
                        }}
                      />
                      <span style={{ opacity: 0 }}>-</span>
                    </>
                  </div>
                  {d?.field_name !== "weekday" && (
                    <div className="col-md-3">
                      {d?.field_name === "hour_of_the_day" ? (
                        <>
                          <TimePicker
                            format={format}
                            value={
                              d?.value?.start_time
                                ? dayjs(d?.value?.start_time, "HH:mm")
                                : null
                            }
                            size="large"
                            onChange={(_) => {
                              setData({
                                ...data,
                                criteria: data.criteria?.map((t, k) => {
                                  if (k === i) {
                                    return {
                                      ...t,
                                      value: {
                                        ...t.value,
                                        start_time: moment(new Date(_)).format(
                                          "HH:mm"
                                        ),
                                      },
                                    };
                                  }
                                  return { ...t };
                                }),
                              });
                            }}
                          />
                          {submit && !d?.value?.start_time ? (
                            <span style={{ color: "red" }}>
                              Start time is required
                            </span>
                          ) : (
                            <span style={{ opacity: 0 }}>-</span>
                          )}
                        </>
                      ) : (
                        <>
                          <Select
                            placeholder="Operator"
                            size="large"
                            style={{ width: "100%" }}
                            options={OperatorList}
                            value={d?.operator}
                            disabled={
                              d?.field_name === "campaign_status" ||
                              d?.field_name === "status"
                            }
                            onChange={(e) => {
                              setData({
                                ...data,
                                criteria: data.criteria?.map((t, k) => {
                                  if (k === i) {
                                    return { ...t, operator: e, value: null };
                                  }
                                  return { ...t };
                                }),
                              });
                            }}
                          />
                          {submit && !d?.operator ? (
                            <span style={{ color: "red" }}>
                              Operator is required
                            </span>
                          ) : (
                            <span style={{ opacity: 0 }}>-</span>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className={`${
                      d?.field_name === "weekday" ? "col-md-6" : "col-md-3"
                    }`}
                  >
                    {d?.field_name === "hour_of_the_day" ? (
                      <>
                        <TimePicker
                          format={format}
                          value={
                            d?.value?.end_time
                              ? dayjs(d?.value?.end_time, "HH:mm")
                              : null
                          }
                          use12Hours
                          size="large"
                          onChange={(_) => {
                            setData({
                              ...data,
                              criteria: data.criteria?.map((t, k) => {
                                if (k === i) {
                                  return {
                                    ...t,
                                    value: {
                                      ...t.value,
                                      end_time: moment(new Date(_)).format(
                                        "HH:mm"
                                      ),
                                    },
                                  };
                                }
                                return { ...t };
                              }),
                            });
                          }}
                        />
                        {submit && !d?.value?.end_time ? (
                          <span style={{ color: "red" }}>
                            End time is required
                          </span>
                        ) : (
                          <span style={{ opacity: 0 }}>-</span>
                        )}
                      </>
                    ) : d?.field_name === "weekday" ? (
                      <>
                        <Checkbox.Group
                          value={d?.value ? d?.value?.split(",") : null}
                          onChange={(e) => {
                            onChangeValues(e.join(","), i);
                          }}
                        >
                          {weekdays?.map((d, i) => (
                            <Checkbox value={d.value} key={i}>
                              {d?.label}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                        {submit && !d?.value ? (
                          <span style={{ color: "red" }}>
                            Weekday is required
                          </span>
                        ) : (
                          <span style={{ opacity: 0 }}>-</span>
                        )}
                      </>
                    ) : (
                      <>
                        <Input
                          className="form-control form-control-solid fs-7"
                          size="large"
                          style={{ width: "100%" }}
                          disabled={
                            d?.field_name === "campaign_status" ||
                            d?.field_name === "status"
                          }
                          value={
                            d?.field_name === "campaign_status"
                              ? "Out Of Budget"
                              : d?.field_name === "campaign_status"
                              ? "Active"
                              : d?.value
                          }
                          onChange={(e) => {
                            onChangeValues(
                              d?.field_name === "campaign_status" ||
                                d?.field_name === "status"
                                ? e.target.value
                                : NumberWithDecimal(e.target.value),
                              i
                            );
                          }}
                        />
                        {submit && !d?.value ? (
                          <span style={{ color: "red" }}>
                            Value is required
                          </span>
                        ) : (
                          <span style={{ opacity: 0 }}>-</span>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-md-2 ">
                    <div className="d-flex">
                      {i === 0 ? (
                        <button
                          className="btn btn-light-primary btn-icon me-3"
                          onClick={() => {
                            setData({
                              ...data,
                              ...data.criteria?.push({
                                field_name: null,
                                operator: null,
                                value: null,
                              }),
                            });
                          }}
                        >
                          <i className="fa fa-plus" />
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            const data_ = data;
                            data_.criteria?.splice(i, 1);
                            setData({
                              ...data_,
                            });
                          }}
                          className="btn btn-light-danger btn-icon"
                        >
                          <i className="fa fa-minus" />
                        </button>
                      )}
                    </div>
                    <span style={{ opacity: 0 }}>-</span>
                  </div>
                </div>
              ))}
            </div>
          </Panel>
          <Panel
            header="Lookback window"
            style={{ borderBottom: "none" }}
            key="2"
          >
            <Radio.Group
              value={data?.lookback_window}
              onChange={(e) => {
                setData({
                  ...data,
                  lookback_window: e.target.value,
                });
              }}
            >
              <Radio value="today">
                <b>Today</b>
              </Radio>
              <Radio value="yesterday">
                <b>Yesterday</b>
              </Radio>
              <Radio value="one_week">
                <b>One Week (Exclude Today)</b>
              </Radio>
              <Radio value="two_weeks">
                <b>Two Weeks (Exclude Today)</b>
              </Radio>
            </Radio.Group>
          </Panel>
          <Panel header="Operation" key="3" style={{ borderBottom: "none" }}>
            <div className="ps-10">
              <div className="row">
                <div className="col-md-4">
                  <div>
                    <label className="fw-bolder fs-6">Daily Budget Type</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <Radio.Group
                    value={data?.daily_budget_type}
                    onChange={(e) => {
                      setData({
                        ...data,
                        daily_budget_type: e.target.value,
                      });
                    }}
                  >
                    <Radio value="increase">Increase</Radio>
                    <Radio value="decrease">Decrease</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-2 ">
                  <div>
                    <label className="fw-bolder fs-6">Daily Budget</label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <Input
                      className="form-control form-control-solid"
                      name="daily_budget"
                      placeholder="Daily Budget"
                      value={data?.daily_budget || null}
                      addonAfter="%"
                      onChange={(e) => {
                        const reg = /^-?\d*(\.\d*)?$/;
                        if (e.target.value?.length >= 7 || e.target.value < 0) {
                          return;
                        }
                        if (reg.test(e.target.value)) {
                          setData({
                            ...data,
                            daily_budget: e.target.value,
                          });
                        }
                      }}
                    />
                    {submit && !data?.daily_budget && (
                      <span style={{ color: "red" }}>
                        Daily Budget is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-7">
                  <div>
                    <small>
                      <div className="d-flex align-item-center justify-content-between">
                        Minimum Increase Increment need to be{" "}
                        <Input
                          name="min_amount"
                          placeholder="Minimum"
                          addonBefore="$"
                          value={data?.min_amount || null}
                          style={{ width: "120px", marginLeft: "10px" }}
                          onChange={(e) => {
                            const reg = /^-?\d*(\.\d*)?$/;
                            if (reg.test(e.target.value)) {
                              setData({
                                ...data,
                                min_amount: NumberWithDecimal(e.target.value),
                              });
                            }
                          }}
                        />
                      </div>
                      {submit && !data?.min_amount && (
                        <span style={{ color: "red" }}>
                          Min Amount is required
                        </span>
                      )}
                      <br />
                      <div className="d-flex align-item-center justify-content-between">
                        Maximum Increase Increment need to be{" "}
                        <Input
                          name="max_amount"
                          placeholder="Maximum"
                          value={data?.max_amount || null}
                          addonBefore="$"
                          style={{ width: "120px", marginLeft: "10px" }}
                          onChange={(e) => {
                            const reg = /^-?\d*(\.\d*)?$/;
                            if (reg.test(e.target.value)) {
                              setData({
                                ...data,
                                max_amount: NumberWithDecimal(e.target.value),
                              });
                            }
                          }}
                        />
                      </div>
                      {submit && !data?.max_amount && (
                        <span style={{ color: "red" }}>
                          Max Amount is required
                        </span>
                      )}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel header="Frequency" key="4" style={{ borderBottom: "none" }}>
            <div className="ps-10">
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-md-2 ">
                  <div>
                    <label className="fw-bolder fs-6">Set Frequency</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <Select
                      placeholder="Frequency"
                      size="large"
                      style={{ width: "100%" }}
                      options={[
                        { label: "5 Min", value: 5 },
                        { label: "10 Min", value: 10 },
                        { label: "15 Min", value: 15 },
                        { label: "20 Min", value: 20 },
                        { label: "25 Min", value: 25 },
                      ]}
                      value={data?.frequency}
                      onChange={(e) => {
                        setData({
                          ...data,
                          frequency: e,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </ModalWrapper>
    </Modal>
  );
}
