import { CloseOutlined, HomeFilled } from "@ant-design/icons";
import { Breadcrumb, message } from "antd";
import React, { useEffect, useState } from "react";
import Icons from "../../../components/icons";
import ASINLink from "../../../components/router-link";
import ASINTooltip from "../../../components/tooltip";
import { testArr } from "../../../config";
import { nameObject } from ".././../../core/helper/static";
import Wrapper from "./style";
import { useSelector } from "react-redux";

export default function (props) {
  const {
    backToAdmin,
    fakeActionUser,
    backToUser,
    history,
    hideMenus,
    setHideMenus,
    collapsed,
    setCollapsed,
  } = props;
  const [current, setCurrent] = useState({
    name: "",
  });
  const SwitchUserResponse = useSelector(
    (state) => state.User.SwitchUserResponse || {}
  );
  useEffect(() => {
    if (SwitchUserResponse.status) {
      backToUser(SwitchUserResponse?.data?.user_data);
      history.replace("/product-research");
      fakeActionUser("SwitchUserResponse");
    }
  }, [SwitchUserResponse]);
  useEffect(() => {
    if (nameObject[history.location.pathname]) {
      setCurrent(nameObject[history.location.pathname]);
    } else {
      if (
        Object.keys(nameObject).findIndex((d) =>
          d.includes("/" + history.location.pathname.split("/")[1])
        ) !== -1
      ) {
        return setCurrent(
          nameObject["/" + history.location.pathname.split("/")[1]]
        );
      }
      setCurrent({ name: "" });
    }
  }, [history?.location]);

  document.title = current?.name || "Traktor";
  return (
    <Wrapper id="kt_header" className=" header authFadeInBottom">
      {window.innerWidth >= 992 ? (
        <>
          <div
            className="arrow"
            style={{
              transition: "width 0.4s, 0.4s",
              animation: ".5s linear",
              transform: `rotate(${collapsed ? "180" : "0"}deg)`,
            }}
            onClick={() => setCollapsed()}
          >
            <Icons type="arrow" />
          </div>
        </>
      ) : (
        ""
      )}
      <div
        className={`d-flex align-items-center flex-wrap ${
          window.innerWidth >= 690
            ? "justify-content-between"
            : window.innerWidth <= 690
            ? !hideMenus
              ? "justify-content-between"
              : "justify-content-between"
            : "justify-content-between"
        } ${
          window.innerWidth >= 400
            ? "container-fluid"
            : "container-fluid-sm w-100"
        }`}
        id="kt_header_container"
      >
        <div
          className={`d-flex align-items-center ${
            window.innerWidth <= 690 ? (!hideMenus ? " " : " ") : ""
          }`}
        >
          {(690 > window.innerWidth || hideMenus) && (
            <div
              onClick={() => setHideMenus(!hideMenus)}
              style={{
                transition: "0.6s",
                transform: `translateX(${!hideMenus ? "95" : "0"}px)`,
              }}
              className="btn btn-icon btn-active-icon-primary"
            >
              <span className="svg-icon svg-icon-1 svg-icon-primary mt-1">
                {!hideMenus ? (
                  <CloseOutlined
                    style={{
                      color: "#6eaf26",
                      marginLeft: "10px",
                    }}
                  />
                ) : (
                  <Icons type="HeaderSvg" />
                )}
              </span>
            </div>
          )}
          <div
            className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2  pb-lg-0 pt-lg-0"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
            style={{
              marginLeft:
                window.innerWidth <= 690 && window.innerWidth >= 500
                  ? !hideMenus
                    ? "16%"
                    : ""
                  : window.innerWidth < 500 && window.innerWidth > 400
                  ? !hideMenus
                    ? "-100%"
                    : ""
                  : window.innerWidth < 400
                  ? !hideMenus
                    ? "-100%"
                    : ""
                  : "0",

              transition: "0.6s",
              position: "relative",
              top: "5px",
            }}
          >
            <Breadcrumb separator={`${current.name ? ">" : ""}`}>
              {testArr?.findIndex((d) => d === "dashboard") !== -1 && (
                <Breadcrumb.Item>
                  <ASINLink
                    to={{
                      pathname: `/dashboard`,
                    }}
                  >
                    <span
                      style={{
                        color: `${
                          current?.name === "Dashboard"
                            ? "#626262"
                            : "#898989c9"
                        }`,
                        position: "relative",
                        top: "-1px",
                      }}
                    >
                      <HomeFilled className="me-2" />
                      {current?.name === "Dashboard" ? "Dashboard" : ""}
                    </span>
                  </ASINLink>
                </Breadcrumb.Item>
              )}

              {current?.name !== "Dashboard" && (
                <>
                  <Breadcrumb.Item className="fw-bolder">
                    {current?.parent ? (
                      <ASINLink
                        to={{
                          pathname: `${current?.path ? current?.path : ""}`,
                        }}
                      >
                        <span
                          style={{
                            color: `${
                              current?.parent ? "#898989c9" : "#626262"
                            }`,
                            position: "relative",
                            top: "-1px",
                          }}
                        >
                          {current?.name
                            ? current?.parent
                              ? current?.parent
                              : current?.name
                            : ""}
                        </span>
                      </ASINLink>
                    ) : (
                      current?.name || ""
                    )}
                  </Breadcrumb.Item>
                  {current?.parent && (
                    <Breadcrumb.Item className="fw-bolder">
                      <span
                        style={{
                          color: "#626262",
                        }}
                      >
                        {current?.name || ""}
                      </span>
                    </Breadcrumb.Item>
                  )}
                </>
              )}
            </Breadcrumb>
          </div>
        </div>

        <div
          style={{
            display:
              window.innerWidth <= 690
                ? !hideMenus
                  ? "flex"
                  : "flex"
                : "flex",
            marginRight: window.innerWidth <= 500 ? "15px" : "0px",
          }}
        >
          {localStorage.getItem("adminData") && (
            <>
              {window.innerWidth <= 500 ? (
                <button
                  className="btn btn-icon mr-10px"
                  id="btnBackToAdmin"
                  style={{ color: "#fff" }}
                  onClick={() => {
                    backToAdmin();
                    message.success("Success");
                    history.replace("/manage-users");
                  }}
                >
                  <ASINTooltip title="Back to admin">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-plus-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      <path
                        fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
                      />
                    </svg>
                  </ASINTooltip>
                </button>
              ) : (
                <button
                  className="btn ml-auto mr-10px"
                  id="btnBackToAdmin"
                  style={{ color: "#fff" }}
                  onClick={() => {
                    backToAdmin();
                    message.success("Success");
                    history.replace("/manage-users");
                  }}
                >
                  Back to admin
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
