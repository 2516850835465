export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_CAMPAIGN_DEFAULT_RULE_SUCCESS":
    case "GET_CAMPAIGN_DEFAULT_RULE_ERROR":
      return {
        ...state,
        GetCampaignDefaultRuleResponse: action.updatePayload,
      };
    case "DEFAULT_RULES_DELETE_SUCCESS":
    case "DEFAULT_RULES_DELETE_ERROR":
      return {
        ...state,
        DeleteCampaignDefaultRuleResponse: action.updatePayload,
      };
    case "STATUS_DEFAULT_RULE_ACTION_SUCCESS":
    case "STATUS_DEFAULT_RULE_ACTION_ERROR":
      return {
        ...state,
        StatusDefaultRuleResponse: action.updatePayload,
      };
    case "ADD_CAMPAIGN_RULES_DEFAULT_SUCCESS":
    case "ADD_CAMPAIGN_RULES_DEFAULT_ERROR":
      return {
        ...state,
        AddCampaignRulesDefaultResponse: action.updatePayload,
      };
    case "EDIT_CAMPAIGN_RULES_DEFAULT_SUCCESS":
    case "EDIT_CAMPAIGN_RULES_DEFAULT_ERROR":
      return {
        ...state,
        EditCampaignRulesDefaultResponse: action.updatePayload,
      };
    case "FAKE_ACTION_DEFAULT_RULES":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
