import axiosCall from "../../../configurations/network-services/axiosCall";

export const getUserAction = (data) => {
  const path = `user?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&user_name=${data?.user_name || ""}`;
  const responseType = "USER_GET";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const switchUserAction = (id) => {
  const path = `user/switch-user/${id}`;
  const responseType = "SWITCH_USER";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const addUserAction = (data) => {
  const path = `user`;
  const responseType = "ADD_USER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const updateUserAction = (id, data) => {
  const path = `user/${id}`;
  const responseType = "UPDATE_USER";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ChangePasswordAction = (data) => {
  const path = `change-password`;
  const responseType = "CHANGE_PASSWORD";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const deleteUserAction = (id) => {
  const path = `user/${id}`;
  const responseType = "DELETE_USER";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionUser = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ADMIN_USER", state: data });
};
