import React, { useState } from "react";
import { Modal, Button, Form, Select, message } from "antd";

const ChangeGroup = ({ open, onClose, data }) => {
  // console.log(
  //   data?.map((d))
  //     .reduce((acc, d) => [...acc, ...(d.campaignRules || [])], [])
  //     ?.map((d) => ({ label: d?.rule_name, value: d?.id })),
  //   "data"
  // );
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    // setLoading(true);
    // if (type === "edit") {
    //   EditGroupListAction({ ...selectedRow, ...values });
    // } else {
    //   AddGroupListAction(values);
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const transformed = data.reduce(
    (acc, d) => [
      ...acc,
      ...(d.campaignRules || []).map((rule) => ({
        label: rule.rule_name,
        value: rule.id,
      })),
    ],
    []
  );

  return (
    <>
      <Modal title="Move Rule" open={open} onCancel={onClose} footer={null}>
        <Form
          name="group_name_form"
          // initialValues={{ group_name: selectedRow?.group_name }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="group_name"
            rules={[
              { required: true, message: "Please select your group name!" },
            ]}
          >
            <Select placeholder="Group Name" />
          </Form.Item>
          <Form.Item
            name="rule_name"
            rules={[
              { required: true, message: "Please select your Rule name!" },
            ]}
          >
            <Select options={transformed} placeholder="Rule Name" />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "10px",
            }}
          >
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangeGroup;
