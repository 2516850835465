import React from "react";
import { Wrapper } from "./style";

export default function () {
  return (
    <Wrapper>
     
<div class="line-wobble"></div>
    </Wrapper>
  );
}
