import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SponsoredBrandCampaigns from "../../modules/pages/advertising-reports/sponsored-brand-campaigns";

import {
  AdvertisingSBCampaignsAction,
  fakeActionAdvertisingSB,
} from "../../redux/modules/advertising-reports/sponsored-brand-campaigns/index.action";

const mapStateToProps = (state) => ({
  AdvertisingSBListResponse: state.AdvertisingSB.AdvertisingSBListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AdvertisingSBCampaignsAction,
      fakeActionAdvertisingSB,
    },
    dispatch
  );

const SponsoredBrandCampaigns_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsoredBrandCampaigns);

export default SponsoredBrandCampaigns_;
