export const nameObject = {
  "/": {
    name: "Dashboard",
  },
  "/dashboard": {
    name: "Dashboard",
  },
  "/manage-users": {
    name: "Manage Users",
  },
  "/rules": {
    name: "Rules",
  },
  "/action-logs": {
    name: "Action Logs",
  },
  "/advertising-reports/sponsored-product-campaigns": {
    name: "Sponsored Product Campaigns",
    parent: "Advertising Reports",
  },
  "/advertising-reports/sponsored-brand-campaigns": {
    name: "Sponsored Brand Campaigns",
    parent: "Advertising Reports",
  },
  "/advertising-reports/sponsored-brand-video-campaigns": {
    name: "Sponsored Brand Video Campaigns",
    parent: "Advertising Reports",
  },
  "/advertising-reports/sponsored-display-campaigns": {
    name: "Sponsored Display Campaigns",
    parent: "Advertising Reports",
  },
  "/setting/profile": {
    name: "Profile",
    parent: "Setting",
  },
  "/setting/marketplace-credentials": {
    name: "Marketplace Credentials",
    parent: "Setting",
  },
  "/application-logs/central-log": {
    name: "Central Log",
    parent: "Application Logs",
  },
  "/application-logs/system-error-log": {
    name: "System Error Log",
    parent: "Application Logs",
  },
};
