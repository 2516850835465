import styled from "styled-components";

export const LoadingWrapper = styled.div`
  .table-loader {
    visibility: visible;
    border: 1px solid transparent !important;
    content: " ";
    width: 100%;
    height: 690px;
    background-image: linear-gradient(rgba(235, 235, 235, 1) 1px, transparent 0),
      linear-gradient(90deg, rgba(235, 235, 235, 1) 1px, transparent 0),
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 15%,
        rgba(255, 255, 255, 0) 30%
      ),
      linear-gradient(rgba(240, 240, 242, 1) 35px, transparent 0);

    background-repeat: repeat;

    background-size: 1px 35px, calc(100% * 0.1666666666) 1px, 30% 100%, 2px 70px;

    background-position: 0 0, 0 0, 0 0, 0 0;

    animation: shine 0.5s infinite;
  }

  @keyframes shine {
    to {
      background-position: 0 0, 0 0, 40% 0, 0 0;
    }
  }
`;
export const Wrapper = styled.div`
.line-wobble {
 --uib-size: 80px;
 --uib-speed: 1.55s;
 --uib-color: black;
 --uib-line-weight: 5px;
 position: absolute;
 inset: 0;
 margin: auto;
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 -webkit-box-align: center;
     -ms-flex-align: center;
         align-items: center;
 -webkit-box-pack: center;
     -ms-flex-pack: center;
         justify-content: center;
 height: var(--uib-line-weight);
 width: var(--uib-size);
 border-radius: calc(var(--uib-line-weight) / 2);
 overflow: hidden;
 -webkit-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
}

.line-wobble::before {
 content: '';
 position: absolute;
 top: 0;
 left: 0;
 height: 100%;
 width: 100%;
 background-color: var(--uib-color);
 opacity: 0.1;
}

.line-wobble::after {
 content: '';
 height: 100%;
 width: 100%;
 border-radius: calc(var(--uib-line-weight) / 2);
 -webkit-animation: wobble var(--uib-speed) ease-in-out infinite;
         animation: wobble var(--uib-speed) ease-in-out infinite;
 -webkit-transform: translateX(-90%);
     -ms-transform: translateX(-90%);
         transform: translateX(-90%);
 background-color: var(--uib-color);
}

@-webkit-keyframes wobble {
 0%,
  100% {
  -webkit-transform: translateX(-90%);
          transform: translateX(-90%);
 }

 50% {
  -webkit-transform: translateX(90%);
          transform: translateX(90%);
 }
}

@keyframes wobble {
 0%,
  100% {
  -webkit-transform: translateX(-90%);
          transform: translateX(-90%);
 }

 50% {
  -webkit-transform: translateX(90%);
          transform: translateX(90%);
 }
}

`;
