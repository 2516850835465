import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SponsoredBrandVideoCampaigns from "../../modules/pages/advertising-reports/sponsored-brand-video-campaigns";

import {
    AdvertisingSBVCampaignsAction,
    fakeActionAdvertisingSBV,
  } from "../../redux/modules/advertising-reports/sponsored-brand-video-campaigns/index.action";
  
  const mapStateToProps = (state) => ({
    AdvertisingSBVListResponse: state.AdvertisingSBV.AdvertisingSBVListResponse,
  });
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        AdvertisingSBVCampaignsAction,
        fakeActionAdvertisingSBV,
      },
      dispatch
    );
  
const SponsoredBrandVideoCampaigns_ = connect(mapStateToProps, mapDispatchToProps)(SponsoredBrandVideoCampaigns);

export default SponsoredBrandVideoCampaigns_;
