import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import NoData from "../../../../components/no-data";
import { useSelector } from "react-redux";
import Loading from "../../../../components/loading";

export default function (props) {
  const {
    show,
    close,
    data,
    CompaignRulesGetCompaingsAction,
    fakeActionRules,
  } = props;

  const CompaignRulesGetCompaingsRes = useSelector(
    (state) => state.Rules.CompaignRulesGetCompaingsResponse
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show) {
      setLoading(true);
      CompaignRulesGetCompaingsAction(data?.id);
    }
    return () => {
      setLoading(false);
    };
  }, [show]);

  useEffect(() => {
    if (CompaignRulesGetCompaingsRes?.status === true) {
      setList(CompaignRulesGetCompaingsRes?.data);
      setLoading(false);
      fakeActionRules("CompaignRulesGetCompaingsResponse");
    } else if (CompaignRulesGetCompaingsRes?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionRules("CompaignRulesGetCompaingsResponse");
    }
  }, [CompaignRulesGetCompaingsRes]);

  return (
    <Modal
      title="Campaigns List"
      open={show}
      width={600}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          Close
        </Button>,
      ]}
    >
      <div
        style={{ maxHeight: "calc(100vh - 310px)", overflow: "auto" }}
        id="scroll-hide-view-modal"
      >
        {loading ? (
          <Loading />
        ) : list?.length !== 0 ? (
          list?.map((d, i) => <div key={i}>{d}</div>)
        ) : (
          <NoData />
        )}
      </div>
    </Modal>
  );
}
