import React from "react";
import Dashboard_ from "../../containers/dashboard";
// const Dashboard_ = React.lazy(() => import("../../containers/dashboard"));
import User_ from "../../containers/admin/user";
import ActionLogs_ from "../../containers/action-logs";
import SponsoredBrandCampaigns_ from "../../containers/advertising-reports/sponsored-brand-campaigns";
import SponsoredBrandVideoCampaigns_ from "../../containers/advertising-reports/sponsored-brand-video-campaigns";
import SponsoredDisplayCampaigns_ from "../../containers/advertising-reports/sponsored-display-campaigns";
import SponsoredProductCampaigns_ from "../../containers/advertising-reports/sponsored-product-campaigns";
import CentralLog_ from "../../containers/application-logs/central-log";
import SystemErrorLog_ from "../../containers/application-logs/system-error-log";
import RulesHide_ from "../../containers/rules/index copy";
import MarketplaceCredentials_ from "../../containers/setting/marketplace-credentials";
import Profile_ from "../../containers/setting/profile";
import DefaultRules_ from "../../containers/admin/default-rules";
// const User_ = React.lazy(() => import("../../containers/admin/user"));
// const ActionLogs_ = React.lazy(() => import("../../containers/action-logs"));
// const SponsoredBrandCampaigns_ = React.lazy(() => import("../../containers/advertising-reports/sponsored-brand-campaigns"));
// const SponsoredBrandVideoCampaigns_ = React.lazy(() => import("../../containers/advertising-reports/sponsored-brand-video-campaigns"));
// const SponsoredDisplayCampaigns_ = React.lazy(() => import("../../containers/advertising-reports/sponsored-display-campaigns"));
// const SponsoredProductCampaigns_ = React.lazy(() => import("../../containers/advertising-reports/sponsored-product-campaigns"));
// const CentralLog_ = React.lazy(() => import("../../containers/application-logs/central-log"));
// const SystemErrorLog_ = React.lazy(() => import("../../containers/application-logs/system-error-log"));
// const Rules_ = React.lazy(() => import("../../containers/rules"));
// const MarketplaceCredentials_ = React.lazy(() => import("../../containers/setting/marketplace-credentials"));
// const Profile_ = React.lazy(() => import("../../containers/setting/profile"));

export const userRoutersList = [
  {
    path: "/",
    key: "dashboard",
    name: Dashboard_,
  },
  {
    path: "/dashboard",
    key: "dashboard",
    name: Dashboard_,
  },
  // {
  //   path: "/rules",
  //   key: "rules",
  //   name: Rules_,
  // },
  {
    path: "/rules",
    key: "rules",
    name: RulesHide_,
  },
  {
    path: "/action-logs",
    key: "action-logs",
    name: ActionLogs_,
  },
  {
    path: "/advertising-reports/sponsored-product-campaigns",
    key: "advertising-reports",
    name: SponsoredProductCampaigns_,
  },
  {
    path: "/advertising-reports/sponsored-brand-campaigns",
    key: "advertising-reports",
    name: SponsoredBrandCampaigns_,
  },
  {
    path: "/advertising-reports/sponsored-brand-video-campaigns",
    key: "advertising-reports",
    name: SponsoredBrandVideoCampaigns_,
  },
  {
    path: "/advertising-reports/sponsored-display-campaigns",
    key: "advertising-reports",
    name: SponsoredDisplayCampaigns_,
  },
  {
    path: "/setting/profile",
    key: "setting",
    name: Profile_,
  },
  {
    path: "/setting/marketplace-credentials",
    key: "setting",
    name: MarketplaceCredentials_,
  },
  {
    path: "/:callback_ads",
    key: "setting",
    name: MarketplaceCredentials_,
  },
  {
    path: "/application-logs/central-log",
    key: "application-logs",
    name: CentralLog_,
  },
  {
    path: "/application-logs/system-error-log",
    key: "application-logs",
    name: SystemErrorLog_,
  },
];

export const adminRoutersList = [
  {
    path: "/",
    key: "dashboard",
    name: Dashboard_,
  },
  {
    path: "/dashboard",
    key: "dashboard",
    name: Dashboard_,
  },
  {
    path: "/default-rules",
    key: "default-rules",
    name: DefaultRules_,
  },
  {
    path: "/manage-users",
    key: "manage-users",
    name: User_,
  },
];
