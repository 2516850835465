import axiosCall from "../../configurations/network-services/axiosCall";

export const GetSystemEventProcessAction = (data) => {
  const path = `user/system-event-process?event_name=${
    data?.event_name || ""
  }&event_status=${data?.event_status || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }&page=${data?.page || 1}&per-page=${data?.perPage || 10}&event_type=${
    data?.event_type || ""
  }&rule_name=${data?.rule_name || ""}`;
  const responseType = "ADMIN_SYSTEM_EVENT_PROCESS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketplacesAction = () => {
  const path = `marketplaces/get-marketplace-by-credential`;
  const responseType = "GET_MARKETPLACE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const EventProcessTypeAction = () => {
  const path = `event-process-type`;
  const responseType = "EVENT_PROCESS_TYPE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const EventNameListAction = (event_type) => {
  const path = `event-name-list?event_type=${event_type || "Campaign Rule"}`;
  const responseType = "EVENT_NAME_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SystemEventLogsListAction = (data) => {
  const path = `user/system-event-logs?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }`;
  const responseType = "SYSTEM_EVENT_LOGS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAppLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_APP_LOGS", state: data });
};
