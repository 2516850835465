import React from "react";
import { Link } from "react-router-dom";
const ASINLink = (props) => {
  const { to, children, id, target, ...rest } = props;
  let obj = { ...to?.state };

  // const changeURL = (type) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     if (type === 1) {
  //       element.hidenUrl = element.href;
  //       element.href = "#";
  //     } else {
  //       element.href = element?.hidenUrl || element.href;
  //     }
  //   }
  // };

  return (
    <>
      <Link
        // onMouseEnter={() => {
        //   changeURL(1);
        // }}
        // onClick={(e) => {
        //   if (e.ctrlKey === true || target === "_blank") {
        //     changeURL(0);
        //     const element = document.getElementById(id);
        //     element.hidenUrl = element.href;
        //     setTimeout(() => {
        //       // element.href = '#'
        //     }, 1);
        //   } else {
        //     changeURL(1);
        //   }
        // }}
        // onMouseUp={() => {
        //   changeURL(0);
        // }}
        // onMouseLeave={(e) => {
        //   changeURL(0);
        // }}
        id={id}
        target={target}
        to={
          typeof to === "string"
            ? to
            : {
                pathname: to?.pathname,
                search: Object.entries(obj)
                  ?.map((d, i) => {
                    return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
                  })
                  ?.join(""),
              }
        }
        {...rest}
      >
        {children}
      </Link>
    </>
  );
};

export default ASINLink;
