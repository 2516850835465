import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DefaultRules from "../../../modules/pages/admin/default-rules";
import {
  getDefaultRulesAction,
  DefaultRulesDeleteAction,
  StatusCampaignRulesAction,
  AddCampaignRulesDefaultAction,
  EditCampaignRulesDefaultAction,
  fakeActionDefaultRules,
} from "../../../redux/modules/admin/default-rules/index.action";

const mapStateToProps = (state) => ({
  GetCampaignDefaultRuleResponse:
    state.DefaultRules.GetCampaignDefaultRuleResponse,
  DeleteCampaignDefaultRuleResponse:
    state.DefaultRules.DeleteCampaignDefaultRuleResponse,
  StatusDefaultRuleResponse: state.DefaultRules.StatusDefaultRuleResponse,
  AddCampaignRulesDefaultResponse:
    state.DefaultRules.AddCampaignRulesDefaultResponse,
  EditCampaignRulesDefaultResponse:
    state.DefaultRules.EditCampaignRulesDefaultResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDefaultRulesAction,
      DefaultRulesDeleteAction,
      StatusCampaignRulesAction,
      AddCampaignRulesDefaultAction,
      EditCampaignRulesDefaultAction,
      fakeActionDefaultRules,
    },
    dispatch
  );

const DefaultRules_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultRules);

export default DefaultRules_;
