import React, { useEffect } from "react";
import Wrapper from "./style";
import { useState } from "react";
import { DefaultPerPage } from "../../../../config";
import Pagination from "../../../../components/pagination";
import NoData from "../../../../components/no-data";
import ASINTable from "../../../../components/table";
import Loading from "../../../../components/loading";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { Statistic, message } from "antd";
import ASINTooltip from "../../../../components/tooltip";

const formatter = (value) => (
  <CountUp decimals={2} end={value} separator={","} />
);

export default function (props) {
  const { AdvertisingSDCampaignsAction, fakeActionAdvertisingSD } = props;
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [data, setData] = useState([]);

  const AdvertisingSDListResponse = useSelector(
    (state) => state.AdvertisingSD.AdvertisingSDListResponse
  );

  useEffect(() => {
    setLoading(true);
    AdvertisingSDCampaignsAction({ page: page, perPage: pageSize });
    return () => {};
  }, []);

  useEffect(() => {
    if (AdvertisingSDListResponse?.status === true) {
      setLoading(false);
      setData(AdvertisingSDListResponse.data.records);
      setTotalPage(AdvertisingSDListResponse.data.pagination.totalCount);
      fakeActionAdvertisingSD("AdvertisingSDListResponse");
    } else if (AdvertisingSDListResponse?.status === false) {
      setLoading(false);
      message.warning(AdvertisingSDListResponse?.message);
      fakeActionAdvertisingSD("AdvertisingSDListResponse");
    }
  }, [AdvertisingSDListResponse]);

  const onPageNo = (e) => {
    setLoading(true);
    AdvertisingSDCampaignsAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    AdvertisingSDCampaignsAction({
      page: 1,
      perPage: e,
    });
  };

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return <ASINTooltip>{(page - 1) * pageSize + 1 + i}</ASINTooltip>;
      },
    },
    {
      title: "Report Date",
      align: "left",

      render: (item) => {
        return <ASINTooltip rule>{item?.report_date || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Tactic Name",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.tactic_name || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Tactic Type",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.tactic_type || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Campaign Name",
      align: "left",
      width: 200,
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.campaign_name || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Campaign Id",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.campaign_id || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Campaign Status",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.campaign_status || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Currency",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.currency || "-"}</ASINTooltip>;
      },
    },

    {
      title: "Impressions",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.impressions || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Clicks",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.clicks || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Cost",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            <Statistic
              prefix="$"
              value={item?.cost || 0}
              formatter={formatter}
              valueStyle={{ fontSize: "16px" }}
            />
          </span>
        );
      },
    },
    {
      title: "Attributed Conversions1d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions1d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Conversions7d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions7d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Conversions14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions14d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Conversions30d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions30d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Conversions 1d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions_1d_same_sku || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Conversions 7d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions_7d_same_sku || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Conversions 14d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions_14d_same_sku || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Conversions 30d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_conversions_30d_same_sku || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Units Ordered1d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_units_ordered1d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Units Ordered7d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_units_ordered7d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Units Ordered14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_units_ordered14d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Units Ordered30d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.attributed_units_ordered30d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales1d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>${item?.attributed_sales1d || "0"}</ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales7d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>${item?.attributed_sales7d || "0"}</ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>${item?.attributed_sales14d || "0"}</ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales30d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>${item?.attributed_sales30d || "0"}</ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales 1d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
           ${item?.attributed_sales_1d_same_sku || "0"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales 7d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            ${item?.attributed_sales_7d_same_sku || "0"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales 14d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            ${item?.attributed_sales_14d_same_sku || "0"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales 30d Same Sku",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            ${item?.attributed_sales_30d_same_sku || "0"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Marketplace Id",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.marketplace_id || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Marketplace",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.marketplace || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Profile Id",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.profile_id || "-"}</ASINTooltip>;
      },
    },
  ];

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <h2 className="mb-6">Campaigns Report Data</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              {/* <div className="card-header ml-auto align-items-center gap-3"></div> */}
              <div className="separator separator-dashed mb-0" />
              <div className="card-body py-4" id="user-table-admin">
                {loading ? (
                  <Loading />
                ) :  <ASINTable
                    columns={columns}
                    fixed="top"
                    dataSource={data}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                  />}
                <Pagination
                  loading={loading || data?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
