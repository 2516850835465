import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SponsoredDisplayCampaigns from "../../modules/pages/advertising-reports/sponsored-display-campaigns";

import {
  AdvertisingSDCampaignsAction,
  fakeActionAdvertisingSD,
} from "../../redux/modules/advertising-reports/sponsored-display-campaigns/index.action";

const mapStateToProps = (state) => ({
  AdvertisingSDListResponse: state.AdvertisingSD.AdvertisingSDListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AdvertisingSDCampaignsAction,
      fakeActionAdvertisingSD,
    },
    dispatch
  );

const SponsoredDisplayCampaigns_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsoredDisplayCampaigns);

export default SponsoredDisplayCampaigns_;
