import React, { useEffect, useState } from "react";
import Wrapper from "./style";
import Loading from "../../../components/loading";
import ASINTable from "../../../components/table";
import Pagination from "../../../components/pagination";
import { DefaultPerPage, timeSince, timeZone } from "../../../config";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import ASINTooltip from "../../../components/tooltip";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Select, message } from "antd";

export default function (props) {
  const {
    getActionLogsListAction,
    fakeActionActionLogs,
    getCampaignRulesListAction,
  } = props;

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [data, setData] = useState([]);

  const [ruleList, setRuleList] = useState([]);
  const [ruleListLoading, setRuleListLoading] = useState(true);

  const [filter, setFilter] = useState({
    campaign_rule_id: "",
    campaign_name: "",
  });

  const GetActionLogsListResponse = useSelector(
    (state) => state.ActionLogs.GetActionLogsListResponse || {}
  );
  const GetCampaignRulesListResponse = useSelector(
    (state) => state.ActionLogs.GetCampaignRulesListResponse || {}
  );

  useEffect(() => {
    if (GetCampaignRulesListResponse?.status === true) {
      setRuleListLoading(false);
      setRuleList(GetCampaignRulesListResponse.data);
      fakeActionActionLogs("GetCampaignRulesListResponse");
    } else if (GetCampaignRulesListResponse?.status === false) {
      setRuleListLoading(false);
      message.warning(GetCampaignRulesListResponse?.message);
      fakeActionActionLogs("GetCampaignRulesListResponse");
    }
  }, [GetCampaignRulesListResponse]);
  useEffect(() => {
    if (GetActionLogsListResponse?.status === true) {
      setLoading(false);
      setData(GetActionLogsListResponse.data.records);
      setTotalPage(GetActionLogsListResponse.data.pagination.totalCount);
      fakeActionActionLogs("GetActionLogsListResponse");
    } else if (GetActionLogsListResponse?.status === false) {
      setLoading(false);
      setData([]);
      message.warning(GetActionLogsListResponse?.message);
      fakeActionActionLogs("GetActionLogsListResponse");
    }
  }, [GetActionLogsListResponse]);

  useEffect(() => {
    setRuleListLoading(true);
    getCampaignRulesListAction();
    setLoading(true);
    setData([]);
    getActionLogsListAction({
      ...filter,
      page: page,
      perPage: pageSize,
    });
    return () => {};
  }, []);

  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    getActionLogsListAction({
      ...filter,
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    setData([]);
    getActionLogsListAction({
      ...filter,
      page: 1,
      perPage: e,
    });
  };

  const columns = [
    {
      title: "#",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Created at",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.created_at * 1000))
                .tz(timeZone)
                .format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(item.created_at)})
            </span>
          </div>
        );
      },
    },
    // {
    //   title: "Log Date",
    //   render: (item) => {
    //     return (
    //       <span>
    //         {item?.log_date || '-'}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Campaign Name",
      width: 180,
      render: (item) => {
        return (
          <b>
            <ASINTooltip rule row={2}>
              {item?.campaign_name || "-"}
            </ASINTooltip>
          </b>
        );
      },
    },
    {
      title: "Campaign Id",
      render: (item) => {
        return <span>{item?.campaign_id || "-"}</span>;
      },
    },
    {
      title: "Campaign Status",
      render: (item) => {
        return <span>{item?.campaign_status || "-"}</span>;
      },
    },
    {
      title: "Campaign New Budget",
      render: (item) => {
        return <span>${item?.campaign_new_budget || "-"}</span>;
      },
    },
    {
      title: "Campaign Previous Budget",
      render: (item) => {
        return <span>${item?.campaign_previous_budget || "-"}</span>;
      },
    },
    {
      title: "Rule Name",
      render: (item) => {
        return <span>{item?.campaignRule?.rule_name || "-"}</span>;
      },
    },
    {
      title: "Record Type",
      render: (item) => {
        return <span>{item?.record_type || "-"}</span>;
      },
    },

    // {
    //   title: "Updated at",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {moment(new Date(item.updated_at * 1000)).tz(timeZone).format(
    //             "MMM DD, YYYY hh:mm A"
    //           )}
    //         </span>
    //         <br />
    //         <span className="timeStampColor">
    //           ({timeSince(item.updated_at)})
    //         </span>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <h2 className="mb-6 smallScreenPageHeader">Central Log System</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header align-items-center">
                <div className="d-flex  align-items-center">
                  <div className="position-relative">
                    <Select
                      placeholder="Select Rule"
                      className="w-200px"
                      size="large"
                      loading={ruleListLoading}
                      options={ruleList?.map((d) => {
                        return { label: d.rule_name, value: d.id };
                      })}
                      value={filter?.campaign_rule_id || null}
                      allowClear
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          campaign_rule_id: e,
                        });
                        setPage(1);
                        setPageSize(DefaultPerPage);
                        setLoading(true);
                        setData([]);
                        getActionLogsListAction({
                          ...filter,
                          campaign_rule_id: e,
                          page: 1,
                          perPage: DefaultPerPage,
                        });
                      }}
                      dropdownStyle={{ minWidth: 260 }}
                    />
                  </div>
                  <div className="position-relative">
                    <Input
                      placeholder="Campaign Name"
                      className="w-200px mx-4"
                      size="large"
                      value={filter?.campaign_name || null}
                      onPressEnter={() => {
                        setPage(1);
                        setPageSize(DefaultPerPage);
                        setLoading(true);
                        setData([]);
                        getActionLogsListAction({
                          ...filter,
                          page: 1,
                          perPage: DefaultPerPage,
                        });
                      }}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          campaign_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {/* <div className="position-relative">
                    <Button
                      onClick={() => {
                        setPage(1);
                        setPageSize(DefaultPerPage);
                        setLoading(true);
                        setData([]);
                        getActionLogsListAction({
                          ...filter,
                          page: 1,
                          perPage: DefaultPerPage,
                        });
                      }}
                      size="large"
                      style={{ color: "#6eaf26" }}
                    >
                      <SearchOutlined width={20} height={20} />
                    </Button>
                  </div> */}
                </div>
              </div>
              <div className="card-body py-4">
                <div className="table-responsive">
                  {loading ? (
                    <Loading />
                  ) : (
                    <ASINTable
                      columns={columns}
                      dataSource={data}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  )}
                </div>
                <Pagination
                  loading={loading || data?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
