import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "./core/helper/asyncComponent";
import RootLoading from "./components/loading/rootLoading";
import Login_ from "./containers/auth";
// const Login_ = React.lazy(() => import("./containers/auth"));

import Pages from "./containers";
// const Pages = React.lazy(() => import("./containers"));

function AppRoute(props) {
  return (
    <BrowserRouter history={props.history}>
      <Suspense fallback={<RootLoading />}>
        <Switch>
          <Route path="/login" component={asyncComponent(Login_)} exact />
          <Route path="/reset-password" component={Login_} />
          <Route path="/" component={asyncComponent(Pages)} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoute;
