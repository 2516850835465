import { PlusOutlined } from "@ant-design/icons";
import { Form, Button, Input, message, Upload } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import Wrapper from "./style";
import { EndPoint } from "../../../../config";
import { GlobalContext } from "../../../../commonContext";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function (props) {
  const { updateUserAction, ChangePasswordAction, fakeActionUser } = props;
  const [form] = Form.useForm();
  const contextVar = useContext(GlobalContext);
  const [emailUpdate, setEmailUpdate] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [profileDetailsLoading, setProfileDetailsLoading] = useState(false);
  // const user_ = JSON.parse(localStorage.getItem("user") || {});
  const user_ = contextVar?.data?.profile;

  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const UpdateUserResponse = useSelector(
    (state) => state.User.UpdateUserResponse
  );
  const ChangePasswordResponse = useSelector(
    (state) => state.User.ChangePasswordResponse
  );

  const [userData, setUserData] = useState({
    name: user_?.name,
    email: user_?.email,
    contact_no: user_?.contact_no,
  });

  const [email, setEmail] = useState(user_?.email);
  const [emailLoading, setEmailLoading] = useState(false);

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: user_?.photo ? `${EndPoint}${user_?.photo}` : `/assets/profile.jpg`,
    },
  ]);

  function clean(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === "" ||
        obj[propName] === null ||
        obj[propName] === undefined
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  useEffect(() => {
    if (UpdateUserResponse?.status === true) {
      setProfileDetailsLoading(false);
      setPasswordUpdate(false);
      setEmailUpdate(false);
      setEmailLoading(false);
      message.destroy();
      message.success(UpdateUserResponse?.message);
      localStorage.setItem(
        "user",
        JSON.stringify({ ...user_, ...clean(UpdateUserResponse?.data) })
      );
      contextVar?.updateCommonGlobalVal("profile", {
        ...user_,
        ...clean(UpdateUserResponse?.data),
      });
      fakeActionUser("UpdateUserResponse");
    } else if (UpdateUserResponse?.status === false) {
      setProfileDetailsLoading(false);
      message.destroy();
      message.error(UpdateUserResponse?.message);
      fakeActionUser("UpdateUserResponse");
    }
  }, [UpdateUserResponse]);

  useEffect(() => {
    if (ChangePasswordResponse?.status === true) {
      setPasswordUpdate(false);
      setChangePasswordLoading(false);
      form.resetFields();
      message.destroy();
      message.success(ChangePasswordResponse?.message);
      fakeActionUser();
    } else if (ChangePasswordResponse?.status === false) {
      setChangePasswordLoading(false);
      message.destroy();
      message.error(ChangePasswordResponse?.message);
      fakeActionUser();
    }
  }, [ChangePasswordResponse]);

  const submitEmail = () => {
    setEmailLoading(true);
    updateUserAction(user_?.id, { email: email });
  };

  const submit = () => {
    setProfileDetailsLoading(true);
    if (fileList?.length !== 0 && !fileList?.[0]?.url) {
      updateUserAction(user_?.id, {
        ...userData,
        photo_base64: fileList?.[0]?.thumbUrl,
      });
    } else {
      updateUserAction(user_?.id, userData);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const onPasswordChange = (values) => {
    setChangePasswordLoading(true);
    ChangePasswordAction(values);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/*begin::Container*/}
        <div id="kt_content_container" className="container-fluid">
          {/*begin::Row*/}
          <div className="row gy-5 g-xl-5">
            {/*begin::Col*/}
            <div className="col-xxl-12">
              {/*begin::Basic info*/}
              <div className="card mb-5 mb-xl-10">
                {/*begin::Card header*/}
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_profile_details"
                  aria-expanded="true"
                  aria-controls="kt_account_profile_details"
                >
                  {/*begin::Card title*/}
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Profile Details</h3>
                  </div>
                  {/*end::Card title*/}
                </div>
                {/*begin::Card header*/}
                {/*begin::Content*/}
                <div
                  id="kt_account_settings_profile_details"
                  className="collapse show"
                >
                  {/*begin::Form*/}
                  <form className="form">
                    {/*begin::Card body*/}
                    <div className="card-body border-top border-top-dashed p-9">
                      {/*begin::Input group*/}
                      <div className="row mb-6">
                        {/*begin::Label*/}
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                          Avatar
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                          {/*begin::Image input*/}
                          <div
                            className="image-input image-input-outline"
                            data-kt-image-input="true"
                          >
                            <Upload
                              listType="picture-card"
                              fileList={fileList}
                              onPreview={handlePreview}
                              onChange={handleChange}
                              accept=".png, .jpg, .jpeg"
                            >
                              {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                          </div>
                          <div className="form-text">
                            Allowed file types: png, jpg, jpeg.
                          </div>
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Full Name
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="name"
                            className="form-control form-control-solid"
                            placeholder="Name"
                            value={userData?.name}
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Email Address
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="email"
                            className="form-control form-control-solid"
                            placeholder="E-mail"
                            value={userData?.email}
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                email: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                          <span className="required">Contact Phone</span>
                          <i
                            className="fas fa-exclamation-circle ms-1 fs-7"
                            data-bs-toggle="tooltip"
                            aria-label="Phone number must be active"
                            data-bs-original-title="Phone number must be active"
                            data-kt-initialized={1}
                          />
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="tel"
                            max={10}
                            maxLength={10}
                            name="contact_no"
                            className="form-control form-control-solid"
                            placeholder="Contact No"
                            value={userData?.contact_no}
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                contact_no: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                      <button
                        onClick={() => {
                          setUserData({
                            name: user_?.name,
                            email: user_?.email,
                            contact_no: user_?.contact_no,
                          });
                        }}
                        type="reset"
                        className="btn btn-light btn-active-light-primary me-2 fs-7"
                      >
                        Discard
                      </button>
                      <Button
                        size="large"
                        loading={profileDetailsLoading}
                        disabled={
                          Object.values(userData)?.filter((d) => !d)?.length !==
                          0
                        }
                        onClick={() => {
                          if (
                            Object.values(userData)?.filter((d) => !d)
                              ?.length === 0
                          ) {
                            submit();
                          }
                        }}
                        className="antd-success  fs-7"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card  mb-5 mb-xl-10">
                <div
                  className="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_signin_method"
                >
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Sign-in Method</h3>
                  </div>
                </div>
                <div
                  id="kt_account_settings_signin_method"
                  className="collapse show"
                >
                  <div className="card-body border-top border-top-dashed p-9">
                    <div className="d-flex flex-wrap align-items-center">
                      <div
                        id="kt_signin_email"
                        className={`${emailUpdate ? "d-none" : ""}`}
                      >
                        <div className="fs-6 fw-bold mb-1">Email Address</div>
                        <div className="fw-semibold text-gray-600">
                          {userData?.email}
                        </div>
                      </div>
                      <div
                        id="kt_signin_email_edit"
                        className={`${
                          emailUpdate ? "" : "flex-row-fluid d-none"
                        }`}
                      >
                        {/*begin::Form*/}
                        <form
                          id="kt_signin_change_email"
                          className="form fv-plugins-bootstrap5 fv-plugins-framework"
                          noValidate="novalidate"
                        >
                          <div className="row mb-6">
                            <div className="col-lg-12 mb-4 mb-lg-0">
                              <div className="fv-row mb-0 fv-plugins-icon-container">
                                <label
                                  htmlFor="emailaddress"
                                  className="form-label fs-6 fw-bold mb-3"
                                >
                                  Enter New Email Address
                                </label>
                                <Input
                                  id="emailaddress"
                                  placeholder="Email Address"
                                  name="emailaddress"
                                  size="large"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <Button
                              size="large"
                              loading={emailLoading}
                              onClick={() => {
                                submitEmail();
                              }}
                              id="kt_signin_submit"
                              type="button"
                              className="antd-success  me-2 px-6 fs-7"
                            >
                              Update Email
                            </Button>
                            <button
                              id="kt_signin_cancel"
                              type="button"
                              onClick={() => {
                                setEmail(user_?.email);
                                setEmailUpdate(false);
                              }}
                              className="btn btn-color-gray-400 btn-active-light-primary px-6 fs-7"
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                        {/*end::Form*/}
                      </div>
                      <div
                        id="kt_signin_email_button"
                        className={`${emailUpdate ? "d-none" : "ms-auto"}`}
                      >
                        <button
                          onClick={() => setEmailUpdate(true)}
                          className="btn btn-light btn-active-light-primary fs-7"
                        >
                          Change Email
                        </button>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-6" />
                    <div className="d-flex flex-wrap align-items-center mb-10">
                      <div
                        id="kt_signin_password"
                        className={!passwordUpdate ? "d-block" : "d-none"}
                      >
                        <div className="fs-6 fw-bold mb-1">Password</div>
                        <div className="fw-semibold text-gray-600">
                          ************
                        </div>
                      </div>
                      <div
                        id="kt_signin_password_edit"
                        className={passwordUpdate ? "flex-row-fluid" : "d-none"}
                      >
                        {/*begin::Form*/}
                        {/* <form id="kt_signin_change_password" className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                                    <div className="row mb-1">
                                                        <div className="col-lg-4">
                                                            <div className="fv-row mb-0 fv-plugins-icon-container">
                                                                <label htmlFor="currentpassword" className="form-label fs-6 fw-bold mb-3">Current
                                                                    Password</label>
                                                                <Input.Password size="large" placeholder='Current Password' name="currentpassword" id="currentpassword" />
                                                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="fv-row mb-0 fv-plugins-icon-container">
                                                                <label htmlFor="newpassword" className="form-label fs-6 fw-bold mb-3">New
                                                                    Password</label>
                                                                <Input.Password size="large" placeholder='New Password' name="newpassword" id="newpassword" />
                                                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="fv-row mb-0 fv-plugins-icon-container">
                                                                <label htmlFor="confirmpassword" className="form-label fs-6 fw-bold mb-3">Confirm
                                                                    New Password</label>
                                                                <Input.Password size="large" placeholder='Confirm New Password' name="confirmpassword" id="confirmpassword" />
                                                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                                                        </div>
                                                    </div>
                                                    <div className="form-text mb-5">Password must be at least 8
                                                        character and contain symbols</div>
                                                    <div className="d-flex">
                                                        <button id="kt_password_submit" type="button" className="btn btn-primary me-2 px-6 fs-7">Update
                                                            Password</button>
                                                        <button id="kt_password_cancel" type="button" onClick={() => setPasswordUpdate(false)} className="btn btn-color-gray-400 btn-active-light-primary px-6 fs-7">Cancel</button>
                                                    </div>
                                                </form> */}
                        <Form
                          form={form}
                          name="changePassword"
                          layout="vertical"
                          // onFinish={onFinish}
                        >
                          <div className="row mb-1">
                            <div className="col-lg-4">
                              <Form.Item
                                name="old_password"
                                label="Current Password"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your Current Password!",
                                  },
                                ]}
                                hasFeedback
                              >
                                <Input.Password placeholder="Current Password" />
                              </Form.Item>
                            </div>
                            <div className="col-lg-4">
                              <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your Password!",
                                  },
                                ]}
                                hasFeedback
                              >
                                <Input.Password placeholder="Password" />
                              </Form.Item>
                            </div>
                            <div className="col-lg-4">
                              <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: "Please confirm your password!",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        getFieldValue("password") === value
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error(
                                          "The two passwords that you entered do not match!"
                                        )
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <Input.Password placeholder="Confirm Password" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="form-text mb-5">
                            Password must be at least 8 character and contain
                            symbols
                          </div>
                          <div className="d-flex">
                            <Button
                              loading={changePasswordLoading}
                              id="kt_password_submit"
                              type="button"
                              onClick={() => {
                                form
                                  .validateFields()
                                  .then((values) => {
                                    delete values.confirm;
                                    onPasswordChange(values);
                                  })
                                  .catch((info) => {});
                              }}
                              size="large"
                              className="antd-success me-2 px-6 fs-7"
                            >
                              Update Password
                            </Button>
                            <button
                              id="kt_password_cancel"
                              type="button"
                              onClick={() => {
                                setPasswordUpdate(false);
                                form.resetFields();
                              }}
                              className="btn btn-color-gray-400 btn-active-light-primary px-6 fs-7"
                            >
                              Cancel
                            </button>
                          </div>
                        </Form>
                        {/*end::Form*/}
                      </div>
                      <div
                        id="kt_signin_password_button"
                        className={
                          !passwordUpdate ? "d-block ms-auto" : "d-none"
                        }
                      >
                        <button
                          onClick={() => setPasswordUpdate(true)}
                          className="btn btn-light btn-active-light-primary fs-7"
                        >
                          Reset Password
                        </button>
                      </div>
                      {/*end::Action*/}
                    </div>
                    {/*end::Password*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Sign-in Method*/}
            </div>
            {/*end::Col*/}
          </div>
          {/*end::Row*/}
        </div>
        {/*end::Container*/}
      </div>
    </Wrapper>
  );
}
