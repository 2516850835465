import React, { useEffect } from "react";
import Wrapper from "./style";
import { useState } from "react";
import { DefaultPerPage } from "../../../../config";
import Pagination from "../../../../components/pagination";
import NoData from "../../../../components/no-data";
import ASINTable from "../../../../components/table";
import Loading from "../../../../components/loading";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { Statistic, message } from "antd";
import ASINTooltip from "../../../../components/tooltip";

const formatter = (value) => (
  <CountUp decimals={2} end={value} separator={","} />
);

export default function (props) {
  const { AdvertisingSPCampaignsAction, fakeActionAdvertisingSP } = props;
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [data, setData] = useState([]);

  const AdvertisingSPListResponse = useSelector(
    (state) => state.AdvertisingSP.AdvertisingSPListResponse
  );

  useEffect(() => {
    setLoading(true);
    AdvertisingSPCampaignsAction({ page: page, perPage: pageSize });
    return () => {};
  }, []);

  useEffect(() => {
    if (AdvertisingSPListResponse?.status === true) {
      setLoading(false);
      setData(AdvertisingSPListResponse.data.records);
      setTotalPage(AdvertisingSPListResponse.data.pagination.totalCount);
      fakeActionAdvertisingSP("AdvertisingSPListResponse");
    } else if (AdvertisingSPListResponse?.status === false) {
      setLoading(false);
      message.warning(AdvertisingSPListResponse?.message);
      fakeActionAdvertisingSP("AdvertisingSPListResponse");
    }
  }, [AdvertisingSPListResponse]);

  const onPageNo = (e) => {
    setLoading(true);
    AdvertisingSPCampaignsAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    AdvertisingSPCampaignsAction({
      page: 1,
      perPage: e,
    });
  };

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Report Date",
      align: "left",
      render: (item) => {
        return <span>{item?.report_date || "-"}</span>;
      },
    },
    {
      title: "Campaign Name",
      align: "left",
      width: 200,
      render: (item) => {
        return <ASINTooltip rule>{item?.campaign_name || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Campaign Id",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.campaign_id || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Campaign Status",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.campaign_status || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Campaign Budget Amount",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            <Statistic
              prefix="$"
              value={item?.campaign_budget_amount || 0}
              formatter={formatter}
              valueStyle={{ fontSize: "16px" }}
            />
          </span>
        );
      },
    },
    {
      title: "Campaign Budget Type",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.campaign_budget_type || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Campaign Rule Based Budget Amount",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            <Statistic
              prefix="$"
              value={item?.campaign_rule_based_budget_amount || 0}
              formatter={formatter}
              valueStyle={{ fontSize: "16px" }}
            />
          </span>
        );
      },
    },
    {
      title: "Campaign Appthcable Budget Rule Id",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.campaign_applicable_budget_rule_id || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Campaign Appthcable Budget Rule Name",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.campaign_applicable_budget_rule_name || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Campaign Budget Currency Code",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.campaign_budget_currency_code || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Impressions",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.impressions || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Clicks",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.clicks || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Cost",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            <Statistic
              prefix="$"
              value={item?.cost || 0}
              formatter={formatter}
              valueStyle={{ fontSize: "16px" }}
            />
          </span>
        );
      },
    },
    {
      title: "Purchases1d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.purchases1d || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Purchases7d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.purchases7d || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Purchases14d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.purchases14d || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Purchases30d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.purchases30d || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Purchases Same Sku1d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.purchases_same_sku1d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Purchases Same Sku7d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.purchases_same_sku7d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Purchases Same Sku14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.purchases_same_sku14d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Purchases Same Sku30d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.purchases_same_sku30d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Cthcks1d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_clicks1d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Cthcks7d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_clicks7d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Cthcks14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_clicks14d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Cthcks30d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_clicks30d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Sales1d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>${item?.sales1d || "0"}</ASINTooltip>;
      },
    },
    {
      title: "Sales7d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>${item?.sales7d || "0"}</ASINTooltip>;
      },
    },
    {
      title: "Sales14d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>${item?.sales14d || "0"}</ASINTooltip>;
      },
    },
    {
      title: "Sales30d",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>${item?.sales30d || "0"}</ASINTooltip>;
      },
    },
    {
      title: "Attributed Sales Same Sku1d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            ${item?.attributed_sales_same_sku1d || "0"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales Same Sku7d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            ${item?.attributed_sales_same_sku7d || "0"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales Same Sku14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            ${item?.attributed_sales_same_sku14d || "0"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Attributed Sales Same Sku30d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            ${item?.attributed_sales_same_sku30d || 0}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Same Sku1d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_same_sku1d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Same Sku7d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_same_sku7d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Same Sku14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_same_sku14d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Units Sold Same Sku30d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>{item?.units_sold_same_sku30d || "-"}</ASINTooltip>
        );
      },
    },
    {
      title: "Kindle Edition Normalized Pages Read14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.kindle_edition_normalized_pages_read14d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Kindle Edition Normalized Pages Royalties 14d",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.kindle_edition_normalized_pages_royalties_14d || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Campaign Bidding Strategy",
      align: "left",
      render: (item) => {
        return (
          <ASINTooltip rule>
            {item?.campaign_bidding_strategy || "-"}
          </ASINTooltip>
        );
      },
    },
    {
      title: "Cost Per Click",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            <Statistic
              prefix="$"
              value={item?.cost_per_click || 0}
              formatter={formatter}
              valueStyle={{ fontSize: "16px" }}
            />
          </span>
        );
      },
    },
    {
      title: "Click Through Rate",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            <Statistic
              prefix="$"
              value={item?.click_through_rate || 0}
              formatter={formatter}
              valueStyle={{ fontSize: "16px" }}
            />
          </span>
        );
      },
    },
    {
      title: "Spend",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            <Statistic
              prefix="$"
              value={item?.spend || 0}
              formatter={formatter}
              valueStyle={{ fontSize: "16px" }}
            />
          </span>
        );
      },
    },
    {
      title: "Marketplace Id",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.marketplace_id || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Marketplace",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.marketplace || "-"}</ASINTooltip>;
      },
    },
    {
      title: "Profile Id",
      align: "left",
      render: (item) => {
        return <ASINTooltip rule>{item?.profile_id || "-"}</ASINTooltip>;
      },
    },
  ];

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <h2 className="mb-6">Campaigns Report Data</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              {/* <div className="card-header ml-auto align-items-center gap-3"></div> */}
              <div className="separator separator-dashed mb-0" />
              <div className="card-body py-4" id="user-table-admin">
                {loading ? (
                  <Loading />
                ) : (
                  <ASINTable
                    columns={columns}
                    fixed="top"
                    dataSource={data}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                  />
                )}
                <Pagination
                  loading={loading || data?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
