import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SystemErrorLog from "../../modules/pages/application-logs/system-error-log";
import {
  fakeActionAppLog,
  SystemEventLogsListAction,
} from "../../redux/modules/application-logs/index.action";

const mapStateToProps = (state) => ({
  SystemEventLogsListResponse: state.AppLogs.SystemEventLogsListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fakeActionAppLog,
      SystemEventLogsListAction,
    },
    dispatch
  );

const SystemErrorLog_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemErrorLog);

export default SystemErrorLog_;
