import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../../modules/layout/header";
import { fakeActionUser } from "../../../redux/modules/admin/user/index.action";

const mapStateToProps = (state) => ({
  SwitchUserResponse: state.User.SwitchUserResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fakeActionUser,
    },
    dispatch
  );

const Header_ = connect(mapStateToProps, mapDispatchToProps)(Header);

export default Header_;
