export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "ADVERTISING_S_P_CAMPAIGNS_LIST_SUCCESS":
      case "ADVERTISING_S_P_CAMPAIGNS_LIST_ERROR":
        return {
          ...state,
          AdvertisingSPListResponse: action.updatePayload,
        };
     
      case "FAKE_ACTION_ADVERTISING_SP":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  