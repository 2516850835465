import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
  const path = `login`;
  const responseType = "LOGIN";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const signUpAction = (payload) => {
  console.log(payload, "payload");
  const path = `signup`;
  const responseType = "SIGNUP";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const forgotPasswordAction = (payload) => {
  console.log(payload, "payload");
  const path = `forgot-password`;
  const responseType = "FORGOT_PASSWORD";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const resetPasswordAction = (payload) => {
  console.log(payload, "payload");
  const path = `reset-password`;
  const responseType = "RESET_PASSWORD";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const fakeActionAuth = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AUTH", state: data });
};
