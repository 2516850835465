import { LogoutOutlined } from "@ant-design/icons";
import { Menu, Statistic, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Icons from "../../../components/icons";
import { ModulesList } from "../../../config";
import Wrapper from "./style";
import { GlobalContext } from "../../../commonContext";

const formatter = (value) => <CountUp end={value} separator="," />;
export default function Sidebar(props) {
  const {
    logout,
    // user,
    GetKeepaTokenAction,
    fakeActionCommon,
    collapsed,
    setCollapsed,
    setHideMenus,
    userType,
    hideMenus,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const contextVar = useContext(GlobalContext);
  const [current, setCurrent] = useState("dashboard");
  const [subMenuOpen, setSubMenuOpen] = useState("");
  const user = contextVar?.data?.profile;
  useEffect(() => {
    const splitLoca = location.pathname.split("/");
    if (splitLoca[1]) {
      if (splitLoca.length > 2) {
        setCurrent(splitLoca[2]);
      } else {
        setCurrent(splitLoca[1]);
      }
    } else {
      setCurrent("dashboard");
    }
    if (
      ModulesList({ type: "url" })[location.pathname]?.path?.split("/")?.[1]
    ) {
      setCurrent(
        ModulesList({ type: "url" })[location.pathname]?.path?.split("/")?.[1]
      );
    }
    if (window.innerWidth <= 690) {
      setHideMenus(!hideMenus);
    }
  }, [location]);

  const defaultSubMenuSelected = () => {
    if (collapsed) {
      return [];
    }
    let subMenuPath = window.location.pathname.split("/");
    if (subMenuPath.length === 3) {
      return [subMenuPath?.[1]];
    }
    return [];
  };

  const checkMenu = () => {
    const menuList = ModulesList({
      type: "sidebar",
      mode: userType ? "admin" : "common",
    });
    if (defaultSubMenuSelected()?.length == 0) {
      return [current];
    }
    if (
      menuList?.filter((d) => d?.key === defaultSubMenuSelected()?.[0])
        ?.length == 0
    ) {
      return [""];
    }
    return [current];
  };

  return (
    <Wrapper
      style={{
        height: "100%",
        backgroundColor: "rgb(255 255 255)",
        width: collapsed ? "105px" : "289px",
        minWidth: collapsed ? "105px" : "289px",
        zIndex: window.innerWidth >= 992 ? "999" : "1000",
        position:
          690 > window.innerWidth || hideMenus ? "absolute" : "relative",
        transition: "width 0.4s, 0.4s",
        background: "#f9efed",
        overflowY: "auto",
        overflowX: "hidden",
        transform: `translateX(${hideMenus ? "-106" : "0"}px)`,
        animation: ".5s linear",
      }}
      className="sidebar_asinwiser"
    >
      <div
        style={{
          height: !collapsed ? "300px" : "220px",
          display: "grid",
          justifyItems: "center",
          alignContent: "space-around",
          transition: "width 0.3s, 0.3s",
          animation: ".4s linear",
        }}
      >
        <div>
          <div
            className="aside-logo flex-column-auto px-9  mx-auto cursor-pointer"
            id="kt_aside_logo"
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <img
              src={collapsed ? "/assets/favicon.png" : "/assets/logo.png"}
              style={{ width: "100%" }}
              className="shimmer"
            />
          </div>
        </div>
        <div className="aside-user mb-5 mb-lg-6" id="kt_aside_user">
          <div className="d-flex align-items-center flex-column">
            <div
              style={{ display: "grid", justifyItems: "center" }}
              className="mb-4 symbol symbol-75px"
            >
              <img
                style={{
                  width: collapsed ? "55px" : "75px",
                  height: collapsed ? "55px" : "75px",
                }}
                src="/media/avatars/150-26.jpg"
                onError={(e) => {
                  e.target.src = "/assets/profile.jpg";
                }}
                loading="lazy"
                alt=""
              />
              {collapsed && (
                <Tooltip
                  title={
                    <div className="text-center">
                      <a
                        href="#"
                        className="text-gray-900 text-hover-primary fs-4 fw-boldest"
                      >
                        {user?.name}
                      </a>
                      <span className="text-gray-600 fw-bold d-block fs-7 mb-1">
                        {user?.email}
                      </span>
                    </div>
                  }
                  placement="right"
                >
                  <i
                    style={{ marginTop: "20px" }}
                    className="fa-solid fa-circle-info"
                  ></i>
                </Tooltip>
              )}
            </div>

            {!collapsed && (
              <div className="text-center">
                <a
                  href="#"
                  className="text-gray-900 text-hover-primary fs-4 fw-boldest"
                >
                  {user?.name}
                </a>
                <span className="text-gray-600 fw-bold d-block fs-7 mb-1">
                  {user?.email}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          height: !collapsed ? "calc(100% - 360px)" : "calc(100% - 280px)",
          minHeight: "250px",
          overflow: "auto",
          transition: "width 0.4s, 0.4s",
          animation: ".5s linear",
          minWidth: collapsed ? "105px" : "289px",
          maxWidth: collapsed ? "105px" : "289px",
          display: "flex",
          justifyContent: "center",
        }}
        id="kt_aside_menu_wrapper"
      >
        <Menu
          selectedKeys={checkMenu()}
          mode="inline"
          onClick={(e) => {
            history.replace("/" + e?.keyPath?.reverse().join("/"));
            setCurrent(e?.key);
            if (hideMenus) {
              setHideMenus(false);
            }
          }}
          inlineCollapsed={collapsed}
          items={ModulesList({
            type: "sidebar",
            mode: userType ? "admin" : "common",
          })}
          onOpenChange={(e) => {
            setSubMenuOpen(e?.[1]);
          }}
          defaultOpenKeys={defaultSubMenuSelected()}
        />
      </div>
      <div
        style={{
          height: "60px",
          transition: "width 0.4s, 0.4s",
          animation: ".5s linear",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <button
          className={`btn w-200px ${collapsed ? "mx-3" : "mx-0"}`}
          id="btnLogout"
          style={{ color: "#fff" }}
          onClick={() => {
            logout();
          }}
        >
          <LogoutOutlined
            className={`${
              window.innerWidth >= 992 && !collapsed ? "me-2" : ""
            }`}
            style={{ transform: "rotate(-90deg)" }}
          />{" "}
          {!collapsed ? "Logout" : ""}
        </button>
      </div>
    </Wrapper>
  );
}
